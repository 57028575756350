import React from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import config from '../config';
import FileUpload from '../components/Cabinet/FileUpload';

/* API has the same field list */
interface IEntryVisas {
    id: string,
    fields: {
        Created: string,
        Type: string,
        Shore: string,
        Days: string,
        Total: number,
        'Order status': string,
        'Contract file': object,
        'VISA file': object,
        SponsorLetterUrl: string,
        GuaranteeLetterUrl: string,
        EntryStampUrl: string,
        CanPay?: boolean,
        PaidTo: string,
        Sponsor: string,
        Extensions?: object,
        ClientFullName: string,
        ClientCountry: string,
        ClientPlaceOfBirth?: string,
        ClientPassportNumber: string,
        ClientPassportExpiry: string,
        ClientBirthday: string,
        SponsorLogo: string,
        SponsorFullName: string,
        SponsorBirth: string,
        SponsorPassport: string,
        SponsorRole: string,
        SponsorAddress: string,
        SponsorCompany: string,
    }
}

export function NotifyText({ params, onUploaded }:{params:object|undefined, onUploaded:(fileSrc:string) => void}) {
  const { t } = useTranslation();
  return (
    <div key="notify_upload_entry_stamp">
      {t('notify_upload_entry_stamp')}
      {' '}
      <FileUpload params={params} onUploaded={onUploaded} />
    </div>
  );
}

export const useVisas = (clientId:string = '', freshData:boolean = false) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<IEntryVisas>>([]);
  const [notify, setNotify] = useState<any>();
  const getVisas = useCallback(() => {
    if (clientId) {
      // setIsLoading(true);
      axios
        .get(`${config.api.url}clients/${clientId}/visas`)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [clientId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUploaded = () => {
    getVisas();
    setNotify(undefined);
  };
  const fresh = () => {
    getVisas();
  };
  useEffect(() => {
    if (!data.length || freshData) {
      setIsLoading(true);
      getVisas();
    }
  }, [data.length, freshData, getVisas]);
  useEffect(() => {
    if (data) {
    // eslint-disable-next-line array-callback-return
      data.map((record) => {
        const { fields } = record;
        const params = {
          table: 'Entry visa',
          column: 'EntryStampUrl',
          recordId: record.id,
          bucket: 'EntryStamp',
        };
        if (fields.Shore === 'Offshore' && fields.EntryStampUrl === undefined && fields['Order status'] === 'Visa is ready' && notify === undefined) {
          const text = (
            <NotifyText params={params} onUploaded={handleUploaded} />
          );
          setNotify({ text, type: 'warning' });
        }
      });
    }
  }, [data, handleUploaded, notify]);
  return {
    isLoading,
    data,
    notify,
    fresh,
  };
};

export default useVisas;
