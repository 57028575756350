import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config";
import {Preloader} from "./Preloader";
import {Card, CardBody, CardHeader, LinkDownload, Table, TableDataEmpty, TableWrapper} from "./index.styles";

export default function ReportFiles() {
    const { t } = useTranslation();
    const { clientId } = useParams<{clientId:string}>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<Array<any>>([]);

    useEffect(() => {
      if (clientId) {
        axios
          .get(`${config.api.url}accounting/reports/${clientId}`)
          .then((res) => {
            setData(res.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, [clientId]);

    if (isLoading) {
      return <Preloader isLoading={isLoading} />;
    }

    return (
      <Card>
        <CardHeader>
          <h3>
            {t('your_reports')}
          </h3>
        </CardHeader>
        <CardBody>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>{t('report_name')}</th>
                  <th>{t('report_files')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((record) => {
                  const recordId = record.id;
                  const { reportName, files } = record;
                  return (
                    <tr key={recordId}>
                      <td data-label={t('report_name')}>
                        {reportName}
                      </td>
                      <td data-label={t('report_files')}>
                        {files ? files.map((fileUrl: string) => {
                                const filename = fileUrl.substring(fileUrl.lastIndexOf('-')+1);
                                return (
                                    <>
                                        <LinkDownload href={fileUrl} download target="_blank">
                                          {t('download')}
                                          {' '}
                                          {decodeURIComponent(filename)}
                                        </LinkDownload>
                                        <br />
                                    </>
                                );
                            }
                        ) : '—'}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableWrapper>
          {!data.length
                      && (
                        <TableDataEmpty>
                          {t('table_data_not_found')}
                        </TableDataEmpty>
                      )}
        </CardBody>
      </Card>
    );
}
