import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Container } from 'typedi';
import { Preloader } from '../Cabinet/Preloader';
import { Ils } from '../../loaders/localstorage';

export default function Id() {
  const { id } = useParams<{id:string}>();
  if (id) {
    const ls = Container.get<Ils>('ls');
    ls.set('clientId', id);
    return <Redirect to="/" />;
  }
  return (
    <Preloader isLoading />
  );
}
