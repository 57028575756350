import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { isDesktop } from 'react-device-detect';
import { Preloader } from './Preloader';
import {
  Card,
  CardBody,
  CardHeader,
  TableWrapper,
  Table,
  LinkDownload,
  BadgeRed,
  LinkRemove,
  ActionBar,
  PaymentsWrapper,
  ButtonPayNow,
  PriceWripper,
  Price,
  TdLabel,
  InfoIcon,
  PaidIcon,
  TableDataEmpty,
  VisaDate,
  SponsorCompany,
  OrderStatusDefault,
  OrderStatusNew,
  OrderStatusPaid,
  OrderStatusImmigrationProcessing,
  OrderStatusVisaIsReady,
  OrderStatusRefund,
  OrderStatusFailed,
  OrderStatusDescription,
  OrderStatusesDescription,
} from './index.styles';
import { formattedDateString, getUrlFromField } from './helper';
import ThumbnailViewer from './ThumbnailViewer';
import FileUpload from './FileUpload';
import config from '../../config';
import { PreloaderState } from './PreloaderState';
import DocLackOfPassport from './DocLackOfPassport';

function PayNow({ entryVisaId }:{entryVisaId:string}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleOnClick = () => {
    setIsLoading(true);
    axios
      .post(`${config.api.url}invoices/visa/${entryVisaId}`)
      .then((res) => {
        window.location.href = res.data.invoiceUrl;
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  if (isLoading) {
    return (
      <PaymentsWrapper>
        <PreloaderState isLoading />
      </PaymentsWrapper>
    );
  }
  return (
    <PaymentsWrapper>
      <ButtonPayNow onClick={handleOnClick}>
        <Trans i18nKey="pay_now" />
      </ButtonPayNow>
      {/* <WeAccept> */}
      {/*    <img src={VisaImg} alt="Visa"/> */}
      {/*    <img src={MastercardImg} alt="MasterCard"/> */}
      {/* </WeAccept> */}
    </PaymentsWrapper>
  );
}

// function PopoverPassportControlInfo() {
//   const { t } = useTranslation();
//   return (
//     <OverlayTrigger
//       trigger="click"
//       placement="bottom"
//       rootClose
//       overlay={(
//         <Popover id="popover-passport-control-info" style={{ maxWidth: '320px' }}>
//           <Popover.Body>
//             <div dangerouslySetInnerHTML={{ __html: t('popover_passport_control_info', { returnObjects: true }) }} />
//           </Popover.Body>
//         </Popover>
//       )}
//     >
//       <LinkPopover theme={{ padding: '0', color: 'red' }}>
//         <Trans i18nKey="passing_through_passport_control" />
//       </LinkPopover>
//     </OverlayTrigger>
//   );
// }

function EntryStampInfo() {
  const { t } = useTranslation();
  return (
    <>
      <Trans i18nKey="visa_entry_stamp" />
      <OverlayTrigger
        trigger="click"
        placement="top"
        rootClose
        overlay={(
          <Popover id="popover-entry-stamp-info">
            <Popover.Body>
              {t('visa_entry_stamp_info') as string}
            </Popover.Body>
          </Popover>
        )}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>
          <InfoIcon icon={faQuestionCircle} style={{ marginLeft: '5px', cursor: 'pointer' }} />
        </a>
      </OverlayTrigger>
    </>
  );
}

function EntryStampBlock({ entryVisaId, entryStampUrl, fresh }:{entryVisaId: string, entryStampUrl:string, fresh?:()=>void}) {
  const params = {
    table: 'Entry visa',
    column: 'EntryStampUrl',
    recordId: entryVisaId,
    bucket: 'EntryStamp',
  };
  const [imageSrc, setImageSrc] = useState(entryStampUrl !== undefined ? entryStampUrl : '');
  const [action, setAction] = useState(imageSrc !== '' ? 'remove' : 'add');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleRemove = () => {
    setIsLoading(true);
    axios
      .delete(`${config.api.url}visas/${entryVisaId}/remove-entry-stamp`)
      .then(() => {
        setAction('add');
        setIsLoading(false);
        if (fresh) {
          fresh();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const handleUploaded = (fileSrc:string) => {
    setImageSrc(fileSrc);
    setAction('remove');
  };
  if (action === 'add') {
    return (
      <ActionBar key={imageSrc}>
        <FileUpload params={params} onUploaded={handleUploaded} />
      </ActionBar>
    );
  }
  // action remove
  return (
    <ActionBar key={imageSrc}>
      <ThumbnailViewer img={imageSrc} />
      {!isLoading
          && (
            <LinkRemove onClick={handleRemove}>
              <Trans i18nKey="delete" />
            </LinkRemove>
          )}
      {isLoading
          && <PreloaderState isLoading />}
    </ActionBar>
  );
}

function generateDocument(template: string, data: object): Promise<string> {
  return new Promise((resolve, reject) => {
    axios
      .post(`https://gen.legalindonesia.id/api/pdf?type=plane&template=${template}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

interface IGenDoc {
    template: string,
    client: object,
    sponsor: object,
    entryVisaId: string,
    columnName: string,
}

function GenerateDocBlock({
  template, client, sponsor, entryVisaId, columnName,
}:IGenDoc) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docUrl, setDocUrl] = useState<string>('');
  const onClick = () => {
    setIsLoading(true);
    if (!docUrl) {
      const data = {
        sponsor,
        client,
      };
      generateDocument(template, data)
        .then((url) => {
          window.open(url);
          setDocUrl(url);
          setIsLoading(false);
        });
    } else {
      window.open(docUrl);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (docUrl !== '') {
      const data = {
        template: columnName,
        url: docUrl,
      };
      axios
        .post(`${config.api.url}visas/${entryVisaId}/save-doc-url`, data)
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [columnName, docUrl, entryVisaId]);
  if (isLoading) {
    return (
      <PreloaderState isLoading />
    );
  }
  return (
    <LinkDownload onClick={onClick}>
      {t('download') as string}
    </LinkDownload>
  );
}

/* API has the same field list */
interface IEntryVisas {
    id: string,
    fields: {
        Created: string,
        Type: string,
        Shore: string,
        Days: string,
        Total: number,
        'Order status': string,
        'Contract file': object,
        'VISA file': object,
        HasVisaFileDOUrl?: boolean,
        SponsorLetterUrl: string,
        GuaranteeLetterUrl: string,
        EntryStampUrl: string,
        CanPay?: boolean,
        Sponsor: string,
        Extensions?: object,
        ClientFullName: string,
        ClientCountry: string,
        ClientPlaceOfBirth?: string,
        ClientPassportNumber: string,
        ClientPassportExpiry: string,
        ClientBirthday: string,
        SponsorLogo: string,
        SponsorFullName: string,
        SponsorBirth: string,
        SponsorPassport: string,
        SponsorRole: string,
        SponsorAddress: string,
        SponsorCompany: string,
    }
}

export function RenderDocLetter({
  fields, template, client, sponsor, recordId, columnName,
}:{
  fields:any, template:string, client:object, sponsor:any, recordId: string, columnName:string
}) {
  const { t } = useTranslation();
  const anotherConditions = !!fields.Sponsor && fields['Order status'] === 'Visa is ready'
          && !fields.Extensions && ['D2', 'C2'].includes(fields.Type);
  if (!!fields[columnName] && anotherConditions) {
    return (
      <>
        <LinkDownload href={fields[columnName]} download target="_blank">
          {t('download') as string}
        </LinkDownload>
        <br />
        <SponsorCompany>{sponsor.company}</SponsorCompany>
      </>
    );
  }
  if (!fields[columnName] && anotherConditions) {
    // sponsor-template - visa type is Business
    // sponsor-tourism - visa type is Tourism
    // sponsor-D212 - visa type is Multi entry

    // garantee-template - visa type is Business
    // garantee-tourism - visa type is Tourism
    // garantee-D212 - visa type is Multi entry
    let temp = `${template}-template`;
    if (fields.Type && fields.Type === 'Tourism') {
      temp = `${template}-tourism`;
    }
    else if (fields.Type && fields.Type === 'Multi entry') {
      temp = `${template}-D212`;
    }
    return (
      <>
        <GenerateDocBlock template={temp} client={client} sponsor={sponsor} entryVisaId={recordId} columnName={template} />
        <br />
        <SponsorCompany>{sponsor.company}</SponsorCompany>
      </>
    );
  }
  return (
    <>—</>
  );
}
export function RenderOrderStatus({ name }:{name:string}):any {
  switch (name) {
  case 'New': {
    return (
      <OrderStatusNew>{name}</OrderStatusNew>
    );
  }
  case 'Paid': {
    return (
      <OrderStatusPaid>{name}</OrderStatusPaid>
    );
  }
  case 'Immigration processing': {
    return (
      <OrderStatusImmigrationProcessing>{name}</OrderStatusImmigrationProcessing>
    );
  }
  case 'Visa is ready': {
    return (
      <OrderStatusVisaIsReady>{name}</OrderStatusVisaIsReady>
    );
  }
  case 'Refund': {
    return (
      <OrderStatusRefund>{name}</OrderStatusRefund>
    );
  }
  case 'Failed': {
    return (
      <OrderStatusFailed>{name}</OrderStatusFailed>
    );
  }
  default: {
    return (
      <OrderStatusDefault>{name}</OrderStatusDefault>
    );
  }
  }
}

export function RenderOrderStatusesDescription() {
  const { t } = useTranslation();
  const statuses = [
    { name: 'New', description: t('visa_order_status_new') },
    { name: 'Paid', description: t('visa_order_status_paid') },
    { name: 'Immigration processing', description: t('visa_order_status_immigration_processing') },
    { name: 'Visa is ready', description: t('visa_order_status_visa_is_ready') },
    { name: 'Refund', description: t('visa_order_status_refund') },
    { name: 'Failed', description: t('visa_order_status_failed') },
  ];
  return (
    <OrderStatusesDescription>
      {statuses.map((status) => (
        <OrderStatusDescription
        key={status.name}
        >
          <RenderOrderStatus key={status.name} name={status.name} />
          {' '}
          {status.description}
        </OrderStatusDescription>
      ))}
    </OrderStatusesDescription>
  );
}

export default function EntryVisas({
   records,
   isLoading,
   fresh,
   canDownloadDocLackOfPassport
}:{
    records:Array<IEntryVisas>,
    isLoading: boolean,
    fresh?: () => void,
    canDownloadDocLackOfPassport: boolean,
}) {
  const { t } = useTranslation();
  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }
  const dataNotFound = !records;
  return (
    <>
      <Card>
        {/* <PopoverPassportControlInfo /> */}
        <CardHeader>
          <h3>{t('your_visas') as string}</h3>
        </CardHeader>
        <CardBody>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>{t('visa_type') as string}</th>
                  <th>{t('visa_price') as string}</th>
                  <th>{t('visa_contract_file') as string}</th>
                  <th>{t('visa_visa_file') as string}</th>
                  <th>{t('visa_order_status') as string}</th>
                  <th>{t('visa_sponsor') as string}</th>
                  <th>{t('visa_guarantee') as string}</th>
                  <th>
                    <EntryStampInfo />
                  </th>
                </tr>
              </thead>
              <tbody>
                {records.map((record) => {
                  const { fields } = record;
                  const visaType = fields.Type;
                  const orderStatus = fields['Order status'];
                  const paid = ['New', 'Refund', 'Failed'].indexOf(orderStatus as string) < 0;
                  const recordId = record.id;
                  const client = {
                    name: fields.ClientFullName,
                    country: fields.ClientCountry,
                    pob: fields.ClientPlaceOfBirth || fields.ClientCountry,
                    passport: fields.ClientPassportNumber,
                    passporttill: fields.ClientPassportExpiry,
                    birthday: fields.ClientBirthday,
                  };
                  const sponsor = {
                    logo: fields.Sponsor ? getUrlFromField(fields.SponsorLogo) : '',
                    fullname: fields.Sponsor ? fields.SponsorFullName : '',
                    company: fields.Sponsor ? fields.SponsorCompany : '',
                    birth: fields.Sponsor ? fields.SponsorBirth : '',
                    passport: fields.Sponsor ? fields.SponsorPassport : '',
                    role: fields.Sponsor ? fields.SponsorRole : '',
                    address: fields.Sponsor ? fields.SponsorAddress : '',
                    stamp: fields.Sponsor ? `https://gen.legalindonesia.id/public/stamps/${fields.Sponsor}/stamp-${Math.floor(Math.random() * 10) + 1}.gif` : '',
                  };
                  return (
                    <tr key={Math.random()}>
                      <td className="caption">
                        {t('visa') as string}
                        {' '}
                        {formattedDateString(fields.Created as string)}
                      </td>
                      <td data-label={t('visa_type') as string}>
                        {fields.Type ? `${fields.Type}, ` : null}
                        {fields.Days ? `${fields.Days}` : null}
                        {isDesktop && (
                          <VisaDate>
                            (
                            {formattedDateString(fields.Created as string)}
                            )
                          </VisaDate>
                        )}
                      </td>
                      <td data-label={t('visa_price') as string}>
                        <PriceWripper>
                          {fields.Total
                            && (
                              <Price
                                value={fields.Total as number}
                                allowEmptyFormatting
                                displayType="text"
                                thousandSeparator
                                suffix=" IDR"
                              />
                            )}
                          {/* {paid && <BadgeGreen>{t('paid')}</BadgeGreen>} */}
                          {paid && <PaidIcon icon={faCheckCircle} />}
                          {orderStatus as string === 'Refund' && <BadgeRed>{orderStatus}</BadgeRed>}
                          {!paid && fields.CanPay && <PayNow entryVisaId={recordId} />}
                        </PriceWripper>
                      </td>
                      <td data-label={t('visa_contract_file')}>
                        {fields['Contract file'] ? (
                          <LinkDownload href={getUrlFromField(fields['Contract file'])} download target="_blank">
                            {t('download') as string}
                          </LinkDownload>
                        ) : '—'}
                      </td>
                      <td data-label={t('visa_visa_file')}>
                        {fields['HasVisaFileDOUrl'] ? (
                          <LinkDownload href={`${config.api.url}visas/${recordId}/download`} download target="_blank">
                            {t('download') as string}
                          </LinkDownload>
                        ) : !fields['HasVisaFileDOUrl'] && fields['VISA file'] ? (
                            <LinkDownload href={getUrlFromField(fields['VISA file'])} download target="_blank">
                                {t('download') as string}
                            </LinkDownload>
                        ) : '—'}
                      </td>
                      <td data-label={t('visa_order_status')}>
                        {visaType === 'VOA' && '—'}
                        {visaType !== 'VOA' && <RenderOrderStatus name={orderStatus as string} />}
                      </td>
                      <td data-label={t('visa_sponsor')}>
                        <RenderDocLetter fields={fields} template="sponsor" client={client} sponsor={sponsor} recordId={recordId} columnName="SponsorLetterUrl" />
                      </td>
                      <td data-label={t('visa_guarantee')}>
                        <RenderDocLetter fields={fields} template="garantee" client={client} sponsor={sponsor} recordId={recordId} columnName="GuaranteeLetterUrl" />
                      </td>
                      <td>
                        <TdLabel>
                          <EntryStampInfo />
                        </TdLabel>
                        {fields.Shore === 'Offshore' && <EntryStampBlock entryVisaId={recordId} entryStampUrl={fields.EntryStampUrl as string} fresh={fresh} />}
                        {fields.Type === 'VOA' && fields.EntryStampUrl && <ThumbnailViewer img={fields.EntryStampUrl as string} />}
                        {fields.Shore === 'Onshore' ? '—' : ''}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableWrapper>
          {dataNotFound
                        && (
                          <TableDataEmpty>
                            {t('table_data_not_found') as string}
                          </TableDataEmpty>
                        )}
        {canDownloadDocLackOfPassport && <DocLackOfPassport />}
        </CardBody>
      </Card>
      {!dataNotFound && <RenderOrderStatusesDescription />}
    </>
  );
}
