import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';

const Wrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    margin: 2px 0;
    border: 1px solid #ebebeb;
    .alert {
      margin: 0;
    }
    .btn-close {
      padding: 8px 10px;
    }
    .alert-dismissible {
      padding-right: 40px;
    }
    a {
      font-weight: 500;
      color: #00ccff;
    }
`;

export default function Notification({ message }:{message:any}):any {
  // const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [notify, setNotify] = useState<any>();
  useEffect(() => {
    if (message) {
      setNotify(message);
      setShow(true);
    }
  }, [message]);
  // let message = '';
  // const location = useLocation();
  // const urlParams = queryString.parse(location.search);
  // const operation = urlParams.operation as string;
  // if (operation) {
  //   if (operation === 'success') {
  //     message = t('notify_payment_success');
  //   } else {
  //     message = t('notify_payment_failure');
  //   }
  // }
  const onClose = () => {
    setShow(false);
  };
  if (!notify) {
    return (
      <>
      </>
    );
  }
  if (!show) {
    return (
      <>
      </>
    );
  }
  const variant = 'warning';
  // eslint-disable-next-line array-callback-return,consistent-return
  return (
    <Wrapper key={notify.text}>
      <Alert variant={notify.type || variant} onClose={onClose} dismissible>
        {notify.text}
      </Alert>
    </Wrapper>
  );
}
