import { useState } from 'react';
import config from '../config';

function setMark(value:string) {
  localStorage.setItem(`m_${config.secretKey}`, value);
}

function getMark() {
  return localStorage.getItem(`m_${config.secretKey}`) || undefined;
}

const useIam = () => {
  const [isManager, setIsManager] = useState<boolean>(!!getMark());
  const activateOptionsForManagers = () => {
    setMark('true');
    setIsManager(true);
  };
  return {
    isManager,
    activateOptionsForManagers,
  };
};

export default useIam;
