import React, {
  ChangeEvent, useState,
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FileUploadLabel } from './index.styles';
import config from '../../config';
import { PreloaderState } from './PreloaderState';

export default function FileUpload(
  {
    params,
    onUploaded,
    onLoading,
    fileType,
    isRequired,
  }
    :
    {
        params?:object,
        onUploaded?:(fileSrc:string) => void,
        onLoading?:(loading:boolean) => void,
        fileType?:string,
        isRequired?: boolean,
    },
) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const endpoint = fileType || 'image';
  const handleOnChange = (e:ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    if (onLoading) {
      onLoading(true);
    }
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('contentType', file.type);
        if (params) {
          Object.entries(params).forEach(([name, value]) => {
            formData.append(name, value);
          });
        }
        axios
          .post(`${config.api.url}upload/${endpoint}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (onUploaded) {
              onUploaded(res.data.url);
              if (onLoading) {
                onLoading(false);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            if (onLoading) {
              onLoading(false);
            }
          });
      }
    } else {
      setIsLoading(false);
      if (onLoading) {
        onLoading(false);
      }
    }
  };

  if (isLoading) {
    return <PreloaderState isLoading />;
  }
  return (
    <FileUploadLabel>
      {t('upload')}
      <input type="file" onChange={handleOnChange} required={isRequired}/>
    </FileUploadLabel>
  );
}
