import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  PageServicesWrapper,
  ListOfServices,
  ServiceLink,
  ServiceIcon,
} from './index.styles';

export default function PageServices() {
  const { clientId } = useParams<{clientId:string}>();
  // console.log({clientId});
  const { t } = useTranslation();
  return (
    <PageServicesWrapper>
      <h3>
        {t('services')}
      </h3>
      <ListOfServices>
        <ServiceLink to={`/${clientId}/visas`}>
          <label>{t('211_visas')}</label>
          <ServiceIcon icon={faChevronRight} />
        </ServiceLink>
        <ServiceLink to={`/${clientId}/extensions`}>
          <label>{t('extensions')}</label>
          <ServiceIcon icon={faChevronRight} />
        </ServiceLink>
        <ServiceLink to={`/${clientId}/deliveries`}>
          <label>{t('deliveries')}</label>
          <ServiceIcon icon={faChevronRight} />
        </ServiceLink>
        <ServiceLink to={`/${clientId}/kitas`}>
          <label>{t('kitas')}</label>
          <ServiceIcon icon={faChevronRight} />
        </ServiceLink>
        <ServiceLink to={`/${clientId}/review`}>
          <label>{t('review')}</label>
          <ServiceIcon icon={faChevronRight} />
        </ServiceLink>
      </ListOfServices>
    </PageServicesWrapper>
  );
}
