import React, {ChangeEvent, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import axios from 'axios';
import {
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import config from '../../config';
import {
  Card,
  CardBody,
  CardHeader, ThankYou,
} from './index.styles';

function Rate({ name, num, onChange }:{ name: string, num: number, onChange: (event: ChangeEvent<HTMLInputElement>) => void }) {
  const { register } = useFormContext();
  return (
    <Form.Check
      inline
      label={num}
      type="radio"
      id={`${name}_${num}`}
      value={num}
      {...register(name as any)}
      onChange={onChange}
    />
  );
}

function InlineRate({ name, onChange }:{ name: string, onChange: (event: ChangeEvent<HTMLInputElement>) => void }) {
  const rates = [];
  for (let i = 1; i <= 10; i += 1) {
    rates.push(<Rate name={name} num={i} key={`${name}_${i}`} onChange={onChange} />);
  }
  return (
    <div>
      { rates.map((rate) => rate) }
    </div>
  );
}

export default function Review() {
  const { t } = useTranslation();
  const { clientId } = useParams<{clientId:string}>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [isSomethingFilled, setIsSomethingFilled] = useState(false);
  const methods = useForm();
  // eslint-disable-next-line no-shadow
  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    const requestData = {
      'data': {
        'client_id': clientId,
        'f1': data.f1 || '',
        'f2': data.f2 || '',
        'f3': data.f3 || '',
        'f4': data.f4 || '',
        'f5': data.f5 || '',
      }
    }
    axios
      .post(`${config.api.url}clients/reviews/add`, requestData)
      .then((res) => {
        setShowThankYou(true);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSomethingFilled(true);
  };
  return (
    <Card>
      <CardHeader>
        <h3>
          {t('your_review')}
        </h3>
      </CardHeader>
      <CardBody>
        {showThankYou && (
          <ThankYou dangerouslySetInnerHTML={{ __html: t('review_thank_you', { returnObjects: true }) }} />
        )}
        {!showThankYou && (
          <FormProvider {...methods} >
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="f1">
                <Form.Label>{t('review_f1')}</Form.Label>
                <InlineRate name="f1" onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="f2">
                <Form.Label>{t('review_f2')}</Form.Label>
                <InlineRate name="f2" onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="f3">
                <Form.Label>{t('review_f3')}</Form.Label>
                <InlineRate name="f3" onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="f4">
                <Form.Label>{t('review_f4')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...methods.register('f4')}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="f5">
                <Form.Label>{t('review_f5')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...methods.register('f5')}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-2" disabled={isSubmitting || !isSomethingFilled}>
                <>
                  {!isSubmitting && t('review_submit')}
                  {isSubmitting && (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="spinner"
                      />
                      {' '}
                      Loading...
                    </>
                  )}
                </>
              </Button>
            </Form>
          </FormProvider>
        )}
      </CardBody>
    </Card>
  );
}
