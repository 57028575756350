import config from '../config';

export interface Ils {
    set(key:string, value: string): void,
    get(key:string): string | null,
    remove(key:string): void,
}

class Ls implements Ils {
  // eslint-disable-next-line class-methods-use-this
  set = (key: string, value: string): void => {
    localStorage.setItem(`${key}_${config.secretKey}`, value);
  };

  // eslint-disable-next-line class-methods-use-this
  get = (key: string): string | null => localStorage.getItem(`${key}_${config.secretKey}`);

  // eslint-disable-next-line class-methods-use-this
  remove = (key: string): void => {
    localStorage.removeItem(`${key}_${config.secretKey}`);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default async () : Promise<Ils> => new Ls();
