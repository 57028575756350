import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { isDesktop, isMobile } from 'react-device-detect';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { formattedDateString } from './helper';
import { Preloader } from './Preloader';
import {
  Card,
  CardHeader,
  CardBody,
  TableWrapper,
  Table,
  VaccinationShow,
} from './index.styles';
import config from '../../config';
import FileUpload from './FileUpload';
import ThumbnailViewer from './ThumbnailViewer';

function VaccinationBlock({ recordId, vaccinationUrl }: {recordId:string|undefined, vaccinationUrl:string}) {
  const { t } = useTranslation();
  const [fileUrl, setFileUrl] = useState(vaccinationUrl !== undefined ? vaccinationUrl : '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params = {
    table: 'Clients',
    column: 'Vaccination',
    recordId,
    bucket: 'Vaccinations',
  };
  const handleUploaded = (fileSrc:string) => {
    setFileUrl(fileSrc);
  };
  const handleLoading = (loading:boolean) => {
    setIsLoading(loading);
  };
  return (
    <>
      {!isLoading && fileUrl
        ? (
          <VaccinationShow>
            <ThumbnailViewer img={fileUrl} asLink linkText={t('vaccination_show') as string} />
          </VaccinationShow>
        )
        : ''}
      <FileUpload params={params} onUploaded={handleUploaded} onLoading={handleLoading} fileType="file" />
    </>
  );
}

export default function Profile() {
  const { t } = useTranslation();
  const { clientId } = useParams<{clientId:string}>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [vaccinationUrl, setVaccinationUrl] = useState<string>('');
  const [client, setClient] = useState<any>({});

  useEffect(() => {
    if (clientId) {
      axios
        .get(`${config.api.url}clients/${clientId}`)
        .then((res) => {
          const { fields } = res.data;
          if (fields.Vaccination) {
            setVaccinationUrl(fields.Vaccination[0].url);
          }
          setClient(fields);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [clientId]);

  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }

  return (
    <Card isMobile={isMobile}>
      <CardHeader>
        <h3>{t('personal_profile')}</h3>
      </CardHeader>
      <CardBody>
        {isDesktop
          ? (
            <Form>
              <Form.Group as={Row} className="mb-1" controlId="fullName">
                <Form.Label column sm={3}>{t('full_name')}</Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" defaultValue={client['Full name'] as string} readOnly />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="email">
                <Form.Label column sm={3}>{t('e_mail')}</Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" defaultValue={client.Email as string} readOnly />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="countryOfResidence">
                <Form.Label column sm={3}>{t('country_of_residence')}</Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" defaultValue={client.Country as string} readOnly />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="birthDate">
                <Form.Label column sm={3}>{t('birth_date')}</Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    defaultValue={formattedDateString(client.Birthday as string)}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="passportNumber">
                <Form.Label column sm={3}>{t('passport_number')}</Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    defaultValue={client['Passport number'] as string}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="passportIssuedAt">
                <Form.Label column sm={3}>{t('passport_issued_at')}</Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    defaultValue={formattedDateString(client['Passport issued at'] as string)}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="passportExpired">
                <Form.Label column sm={3}><Trans i18nKey="passport_expired" /></Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    defaultValue={formattedDateString(client['Passport expiry'] as string)}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="passportIssuedBy">
                <Form.Label column sm={3}>{t('passport_issued_by')}</Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    defaultValue={client['Passport issued by'] as string}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="whatsapp">
                <Form.Label column sm={3}>{t('whatsapp')}</Form.Label>
                <Col sm={4}>
                  <Form.Control type="text" defaultValue={client.WA as string} readOnly />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1" controlId="vaccination">
                <Form.Label column sm={3}>{t('vaccination')}</Form.Label>
                <Col sm={4} className="pt-2">
                  <VaccinationBlock recordId={clientId} vaccinationUrl={vaccinationUrl} />
                </Col>
              </Form.Group>
            </Form>
          )
          : (
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th>{t('full_name')}</th>
                    <th>{t('e_mail')}</th>
                    <th>{t('country_of_residence')}</th>
                    <th>{t('birth_date')}</th>
                    <th>{t('passport_number')}</th>
                    <th>{t('passport_issued_at')}</th>
                    <th>{t('passport_expired')}</th>
                    <th>{t('passport_issued_by')}</th>
                    <th>{t('whatsapp')}</th>
                    <th>{t('vaccination')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label={t('full_name')}>
                      {client['Full name']}
                    </td>
                    <td data-label={t('e_mail')}>
                      {client.Email}
                    </td>
                    <td data-label={t('country_of_residence')}>
                      {client.Country}
                    </td>
                    <td data-label={t('birth_date')}>
                      {formattedDateString(client.Birthday as string)}
                    </td>
                    <td data-label={t('passport_number')}>
                      {client['Passport number']}
                    </td>
                    <td data-label={t('passport_issued_at')}>
                      {formattedDateString(client['Passport issued at'] as string)}
                    </td>
                    <td data-label={t('passport_expired')}>
                      {formattedDateString(client['Passport expiry'] as string)}
                    </td>
                    <td data-label={t('passport_issued_by')}>
                      {client['Passport issued by']}
                    </td>
                    <td data-label={t('whatsapp')}>
                      {client.WA}
                    </td>
                    <td data-label={t('vaccination')}>
                      <VaccinationBlock recordId={clientId} vaccinationUrl={vaccinationUrl} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TableWrapper>
          )}
      </CardBody>
    </Card>
  );
}
