import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DocLackOfPassportWrapper, LinkDownload } from './index.styles';
import config from '../../config';
import { PreloaderState } from './PreloaderState';

interface IClientInfo {
    id: string,
    fields: {
        [x: string]: any;
    }
}

const getClientInfo = (clientId:string|undefined):Promise<IClientInfo> => new Promise((resolve, reject) => {
  if (clientId) {
    axios
      .get(`${config.api.url}clients/${clientId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  }
});

const generateDocument = (data: object): Promise<string> => new Promise((resolve, reject) => {
  axios
    .post('https://gen.legalindonesia.id/api/pdf?type=plane&template=letter', data)
    .then((res) => {
      resolve(res.data);
    })
    .catch((error) => {
      reject(error);
    });
});

export default function DocLackOfPassport() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docUrl, setDocUrl] = useState<string>('');
  const { clientId } = useParams<{clientId:string}>();
  const onClick = () => {
    setIsLoading(true);
    if (docUrl) {
      window.open(docUrl, '_blank', 'noreferrer');
      setIsLoading(false);
    } else {
      getClientInfo(clientId)
        .then((clientInfo) => {
          const data = {
            client: {
              name: clientInfo.fields['Full name'],
              passport: clientInfo.fields['Passport number'],
              country: clientInfo.fields.Country,
            },
          };
          generateDocument(data)
            .then((url) => {
              setDocUrl(url);
              window.open(url);
              setIsLoading(false);
            });
        });
    }
  };
  return (
    <DocLackOfPassportWrapper>
      {!isLoading
          && (
            <LinkDownload onClick={onClick}>
              {t('doc_lack_of_passport')}
            </LinkDownload>
          )}
      {isLoading
          && <PreloaderState isLoading />}
    </DocLackOfPassportWrapper>
  );
}
