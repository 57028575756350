import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {ActionBar, LinkRemove} from "../index.styles";
import FileUpload from "../FileUpload";
import ThumbnailViewer from "../ThumbnailViewer";
import {PreloaderState} from "../PreloaderState";
import {useFormContext} from "react-hook-form";

export function FileInput({
    name,
    value,
    isRequired,
}:{
    name: string,
    value?: string,
    isRequired?: boolean,
}) {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const [imageSrc, setImageSrc] = useState(value !== undefined ? value : '');
  const [action, setAction] = useState(imageSrc !== '' ? 'remove' : 'add');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleRemove = () => {
    setIsLoading(true);
    setAction('add');
    setIsLoading(false);
  };
  const handleUploaded = (fileSrc:string) => {
    setImageSrc(fileSrc);
    setAction('remove');
  };
  if (action === 'add') {
    return (
      <ActionBar key={imageSrc}>
        <FileUpload onUploaded={handleUploaded} fileType="space" isRequired={isRequired} />
      </ActionBar>
    );
  }
  if (!imageSrc) {
    return (
      <>
        —
      </>
    );
  }
  return (
    <ActionBar key={imageSrc}>
      <ThumbnailViewer img={imageSrc} />
      <input type="hidden" value={imageSrc} {...register(name as any)} />
      {!isLoading
       && (
         <LinkRemove onClick={handleRemove}>
           {t('delete')}
         </LinkRemove>
       )}
      {isLoading
       && <PreloaderState isLoading />}
    </ActionBar>
  );
}
