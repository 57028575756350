import React from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import loaders from './loaders';
import Cabinet from './components/Cabinet';
import Id from './components/Auth/Id';
import Login from './components/Auth/Login';
import './config/i18n';
import Manager from './components/Iam/Manager';

async function startApp() {
  await loaders();
  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/auth/:id">
          <Id />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/iam/manager">
          <Manager />
        </Route>
        <Route path="/:clientId/:page?">
          <Cabinet />
        </Route>
      </Switch>
    </BrowserRouter>,
    document.getElementById('root'),
  );
}

startApp();
