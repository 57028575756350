import styled from 'styled-components';

export const LanguagesWrapper = styled.div`
  display: flex;
  line-height: 35px;
`;

export const CurrentItem = styled.a`
  padding: 9px 12px;
  cursor: pointer;
  text-decoration: none;
  color: #002F34;
  font-weight: 500;
  .language {
    font-size: 20px;
    margin-right: 5px;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
    background-size: cover;
    vertical-align: baseline;
  }
`;

export const ListItems = styled.div`
    position: absolute;
    right: auto;
    left: auto;
    z-index: 1000;
    margin-top: 50px;
    margin-left: -5px;
    background: #fff;
    padding: 10px 15px 5px;
    border: 1px solid #e8e8e8;
`;

export const ListItem = styled.a`
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: #002F34;
  .language {
    font-size: 20px;
    margin-right: 5px;
    border-radius: 3px;
    border: 1px solid #ebebeb;
    background-size: cover;
    vertical-align: baseline;
  }
`;
