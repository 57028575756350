import React from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { FooterWrapper } from './index.styles';
import WhatsappImg from './images/whatsapp.png';
import TelegramImg from './images/telegram.png';

export default function Footer() {
  const year = Moment().format('YYYY');
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <footer>
        <Container>
          <Row>
            <Col md={6} xs={12}>
              &copy;
              {' '}
              {year}
              , PT. LEGAL INDONESIA SERVICE,
              {' '}
              <a
                href="https://LegalIndonesia.id"
                rel="noreferrer"
                target="_blank"
              >
                LegalIndonesia.id
              </a>
              <br />
              <a href="https://goo.gl/maps/XdaRKmmBkPHgZHvU6" rel="noreferrer" target="_blank">
                {t('footer_our_office')}
              </a>
            </Col>
            <Col md={6} xs={12} className="contact-us">
              <span>{t('contact_us')}</span>
              <a href="https://wa.me/628179677771" target="_blank" title="Chat with us on WhatsApp" rel="noreferrer">
                <img src={WhatsappImg} alt="WhatsApp" />
              </a>
              <a href="https://t.me/legalindonesia" target="_blank" title="Chat with us on Telegram" rel="noreferrer">
                <img src={TelegramImg} alt="Telegram" />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </FooterWrapper>
  );
}
