import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Preloader } from '../Preloader';
import config from '../../../config';
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  TableWrapper,
  TableDataEmpty, LinkDownload,
} from '../index.styles';
import {formattedDateString, getUrlFromField} from '../helper';
import ThumbnailViewer from "../ThumbnailViewer";
import BioKitas from "./BioKitas";

export default function Kitas() {
  const { t } = useTranslation();
  const { clientId } = useParams<{clientId:string}>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<any>>([]);
  useEffect(() => {
    if (clientId) {
      axios
        .get(`${config.api.url}clients/${clientId}/kitas`)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [clientId]);

  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }
  return (
    <Card>
      <CardHeader>
        <h3>
          {t('your_kitas')}
        </h3>
      </CardHeader>
      <CardBody>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>{t('kitas_type') as string}</th>
                <th>{t('kitas_file') as string}</th>
                <th>{t('kitas_status') as string}</th>
                <th>{t('kitas_qr') as string}</th>
                <th>{t('kitas_bio') as string}</th>
                <th>{t('kitas_last_day') as string}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((record) => {
                const recordId = record.id;
                const { fields } = record;
                const qrKitasLink = fields['QRKitas'] ? getUrlFromField(fields['QRKitas']) : fields.QRKitasLink;
                return (
                  <tr key={recordId}>
                    <td data-label={t('kitas_type')}>
                      {fields.Type}
                    </td>
                    <td data-label={t('kitas_file')}>
                      {fields.KitasFile ? (
                        <LinkDownload href={getUrlFromField(fields.KitasFile)} download target="_blank">
                          {t('download')}
                        </LinkDownload>
                      ) : '—'}
                    </td>
                    <td data-label={t('kitas_status')}>
                      {fields.Status}
                    </td>
                    <td data-label={t('kitas_qr')}>
                      {qrKitasLink && <ThumbnailViewer img={qrKitasLink}/>}
                      {!qrKitasLink && '—'}
                    </td>
                    <td data-label={t('kitas_bio')}>
                      {(fields.Status === 'In progress' && qrKitasLink ) && (
                          <BioKitas kitasId={recordId}
                                    bioComplete={fields.BioKitas}/>
                      )}
                      {fields.Status !== 'In progress' && fields.BioKitas !== undefined && formattedDateString(fields.BioKitas)}
                      {fields.Status !== 'In progress' && fields.BioKitas === undefined && '—'}
                    </td>
                    <td data-label={t('kitas_last_day')}>
                      {fields.KitasLastDay !== undefined && formattedDateString(fields.KitasLastDay as string)}
                      {fields.KitasLastDay === undefined && '—'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
        {!data.length
                    && (
                      <TableDataEmpty>
                        {t('table_data_not_found')}
                      </TableDataEmpty>
                    )}
      </CardBody>
    </Card>
  );
}
