import React from 'react';
import {
  NavLink, Link, useLocation, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ServicesWrapper,
  ServicesHeader,
  BreakLine,
  ServicesSliderWrapper,
  ServicesSlider,
  ServicesSliderItem,
} from './index.styles';

export default function Services() {
  const { clientId } = useParams<{clientId:string}>();
  const { t } = useTranslation();
  const location = useLocation();
  if (location.pathname === `/${clientId}/services`) {
    return (
      <>
      </>
    );
  }
  return (
    <ServicesWrapper>
      <ServicesHeader>
        <h3>{t('services')}</h3>
        <Link to={`/${clientId}/services`}>
          {t('show_all')}
        </Link>
      </ServicesHeader>
      <BreakLine />
      <ServicesSliderWrapper>
        <ServicesSlider>
          <ServicesSliderItem>
            <NavLink to={`/${clientId}/visas`}>{/* activeClassName="selected" */}
              <label>{t('211_visas')}</label>
            </NavLink>
          </ServicesSliderItem>
          <ServicesSliderItem>
            <NavLink to={`/${clientId}/extensions`}>{/* activeClassName="selected" */}
              <label>{t('extensions')}</label>
            </NavLink>
          </ServicesSliderItem>
          <ServicesSliderItem>
            <NavLink to={`/${clientId}/deliveries`}>{/* activeClassName="selected" */}
              <label>{t('deliveries')}</label>
            </NavLink>
          </ServicesSliderItem>
          <ServicesSliderItem>
            <NavLink to={`/${clientId}/kitas`}>{/* activeClassName="selected" */}
              <label>{t('kitas')}</label>
            </NavLink>
          </ServicesSliderItem>
          <ServicesSliderItem>
            <NavLink to={`/${clientId}/review`}>{/* activeClassName="selected" */}
              <label>{t('review')}</label>
            </NavLink>
          </ServicesSliderItem>
          {/* <ServicesSliderItem> */}
          {/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
          {/*  <a href={undefined}> */}
          {/*    /!* eslint-disable-next-line react/no-unescaped-entities *!/ */}
          {/*    <label>Driver's license</label> */}
          {/*  </a> */}
          {/* </ServicesSliderItem> */}
          {/* <ServicesSliderItem> */}
          {/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
          {/*  <a href={undefined}> */}
          {/*    <label>Visa in the U.S.</label> */}
          {/*  </a> */}
          {/* </ServicesSliderItem> */}
          {/* <ServicesSliderItem> */}
          {/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
          {/*  <a href={undefined}> */}
          {/*    <label>Rental office</label> */}
          {/*  </a> */}
          {/* </ServicesSliderItem> */}
          {/* <ServicesSliderItem> */}
          {/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
          {/*  <a href={undefined}> */}
          {/*    <label>Accounting</label> */}
          {/*  </a> */}
          {/* </ServicesSliderItem> */}
          {/* <ServicesSliderItem> */}
          {/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
          {/*  <a href={undefined}> */}
          {/*    <label>Processing BPJS</label> */}
          {/*  </a> */}
          {/* </ServicesSliderItem> */}
        </ServicesSlider>
      </ServicesSliderWrapper>
    </ServicesWrapper>
  );
}
