import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
    display: inline-block;
  svg {
      width: 28px;
      height: 28px;
      vertical-align: middle;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export function PreloaderState({ isLoading } : {isLoading?: boolean}): any {
  if (!isLoading) {
    return null;
  }
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#04ccfc" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50" />
        </circle>
      </svg>
    </Wrapper>
  );
}
