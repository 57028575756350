import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { PreloaderState } from "../PreloaderState";
import {BioKitasWrapper, HintRed, Input} from "../index.styles";
import moment from "moment/moment";
import { Trans } from "react-i18next";
import $ from 'jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import {InputGroup} from "react-bootstrap";

export default function BioKitas({ kitasId, bioComplete }: { kitasId: string, bioComplete: any }) {
    const datePickerRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bioDate, setBioDate] = useState(bioComplete !== undefined ? bioComplete : '');

    const handleOnChange = (e: any) => {
        const inputDate = moment(e.date).format('YYYY-MM-DD');
        setBioDate(inputDate);
    };

    useEffect(() => {
        if (datePickerRef.current) {
            $(datePickerRef.current).datepicker({
                format: 'dd/mm/yyyy',
            }).on('changeDate', handleOnChange);
        }
    }, []);

    const handleSaveBioKitas = () => {
        if (bioDate) {
            setIsLoading(true);

            const data = {
                bio_date: bioDate,
            };

            axios
                .post(`${config.api.url}kitas/${kitasId}/save-bio-kitas`, data)
                .then((res) => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    };

    if (isLoading) {
        return (
            <PreloaderState isLoading />
        );
    }

    return (
        <BioKitasWrapper>
            <InputGroup size={'sm'}>
                <Input
                    type="text"
                    ref={datePickerRef}
                    max={moment().format('DD/MM/YYYY')}
                    defaultValue={bioDate ? moment(bioDate).format('DD/MM/YYYY') : ''}
                    className={'form-control'}
                />
                <button
                    onClick={handleSaveBioKitas}
                    className={'btn btn-primary'}
                ><Trans i18nKey="kitas_bio_save" /></button>
            </InputGroup>
            {!bioDate && (
                <div>
                    <HintRed><Trans i18nKey="kitas_bio_hint" /></HintRed>
                </div>
            )}
        </BioKitasWrapper>
    );
}
