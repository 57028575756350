import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import config from '../../../../config';
import {Card, CardHeader, CardBody} from '../../index.styles';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button, Col,
  Form, Row,
  Spinner,
} from 'react-bootstrap';
import {FileInput} from "../../FileInput";
import {useParams} from "react-router-dom";

export function CreateVOA() {
  const { t } = useTranslation();
  const { clientId } = useParams<{clientId:string}>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm();
  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    data.clientId = clientId;
    const requestData = {
      'data': data
    };
    axios
      .post(`${config.api.url}/clients/voa/create`, requestData)
      .then((res) => {
        setIsSubmitting(false);
        window.location.href = `/${clientId}/extensions`;
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log('error:', error);
      });
  };
  return (
      <Card>
        <CardHeader>
          <h3>
            {t('creation_voa')}
          </h3>
        </CardHeader>
        <CardBody>
          <Row className="mt-3">
            <Col md={6}>
              <FormProvider {...methods} >
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3" controlId="f4">
                    <Form.Label className="required">{t('visa_expire_at')}</Form.Label>
                    <Form.Control
                      className="w-auto"
                      type="date"
                      {...methods.register('VisaExpireAt')}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="f4">
                    <Form.Label className="required">{t('entry_stamp')}</Form.Label>
                    <FileInput name="EntryStampLink" isRequired={true} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="f4">
                    <Form.Label>{t('e_voa_visa_file')}</Form.Label>
                    <FileInput name="eVoaVisaFileLink" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="f4">
                    <Form.Label>{t('return_ticket_link')}</Form.Label>
                    <FileInput name="ReturnTicketLink" />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="mt-2" disabled={isSubmitting}>
                    <>
                      {!isSubmitting && t('review_submit')}
                      {isSubmitting && (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="spinner"
                          />
                          {' '}
                          Loading...
                        </>
                      )}
                    </>
                  </Button>
                </Form>
              </FormProvider>
            </Col>
          </Row>
        </CardBody>
      </Card>
  );
}
