import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000000;
    background: #ffffff91;
  svg {
      width: 50px;
      height: 50px;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export function Preloader({ isLoading } : {isLoading?: boolean}): any {
  if (!isLoading) {
    return null;
  }
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#04ccfc" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50" />
        </circle>
      </svg>
    </Wrapper>
  );
}
