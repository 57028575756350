// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: {
    url: process.env.REACT_APP_API_URL || 'https://api.legalindonesia.id/',
  },
  languages: {
    default: 'en',
    list: ['en', 'ru'],
  },
  secretKey: 'nctsG4RRmr', // secret key for validation cookie, session, localStorage values and so on
  airtable: {
    apiKey: 'patxHBQ9nLN2Tt8AX.1b03a1e812a396e09a1de10a697de47112b1d6df29d4e025e86578ea89474998',
    base: 'appQaYm9beVHcWLGv',
  },
  services: [],
};
