import React, { useEffect } from 'react';
import useIam from '../../hooks/useIam';

export default function Manager() {
  const { activateOptionsForManagers } = useIam();
  useEffect(() => {
    activateOptionsForManagers();
  }, [activateOptionsForManagers]);
  return (
    <div className="text-center">
      Additional options is activated!
    </div>
  );
}
