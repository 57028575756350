import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {ThumbnailImage, AsLink, ThumbnailIframe} from './index.styles';

export default function ThumbnailViewer({ img, asLink, linkText }:{img:string, asLink?:boolean, linkText?:string}) : any {
  const [isOpen, setIsOpen] = useState(false);
  const isPdf = img.indexOf('.pdf') >= 0;
  const isImage = !isPdf;
  const handleClick = () => {
    setIsOpen(true);
  };
  return (
    <>
      {!asLink && isPdf && <ThumbnailIframe src={img} onClick={handleClick} />}
      {!asLink && isImage && <ThumbnailImage src={img} onClick={handleClick} />}
      {asLink && linkText && <AsLink onClick={handleClick}>{linkText}</AsLink>}
      {isOpen
            && (
              <Lightbox
                mainSrc={img}
                onCloseRequest={() => { setIsOpen(false); }}
              />
            )}
    </>
  );
}
