import { Container } from 'typedi';
import airtable from './airtable';
import localstorage from './localstorage';

// eslint-disable-next-line import/no-anonymous-default-export
export default async () => {
  try {
    const airtableInstance = await airtable();
    Container.set('airtable', airtableInstance);
    const localstorageInstance = await localstorage();
    Container.set('ls', localstorageInstance);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
