import React from 'react';
import {
  Redirect,
} from 'react-router';
import {
  Route,
  useParams,
} from 'react-router-dom';
import { PageWrapper, Wrap } from './index.styles';
import Header from './Header';
import Services from './Services';
import Profile from './Profile';
import PageServices from './PageServices';
import Visas from './211Visas';
import Extensions from './Extensions';
import Deliveries from './Deliveries';
import Kitas from './Kitas';
import Footer from './Footer';
import useVisas from '../../hooks/useVisas';
import useExtensions from '../../hooks/useExtensions';
import Notification from './Notification';
import Review from './Review';
import {CreateVOA} from "./Extensions/voa/CreateVOA";
import ReportFiles from "./ReportFiles";

function RedirectToVisas() {
  const { clientId } = useParams<{clientId:string}>();
  return (
    <Redirect to={`/${clientId}/visas`} />
  );
}

export default function Cabinet() {
  const { clientId, page } = useParams<{clientId:string, page:string}>();
  const {
    data: visaRecords, isLoading: visaIsLoading, notify: visaNotify, fresh: freshVisas,
  } = useVisas(clientId, page === 'visas');
  const {
    data: extRecords,
    isLoading: extIsLoading,
    notify: extNotify,
    fresh: freshExt,
    canCreateNextExtensionFor211Visa,
    getNextExtensionFor211VisaParams,
    canCreateVOAExtension,
    canDownloadDocLackOfPassport,
  } = useExtensions(clientId, ['extensions', 'create-voa'].indexOf(page) >= 0);
  return (
    <PageWrapper>
      <Route path="/:clientId">
        <Header />
      </Route>
      <Wrap>
        {visaNotify && <Notification message={visaNotify} />}
        {extNotify && <Notification message={extNotify} />}
        <Route path="/:clientId/services" exact={true}>
          <PageServices />
        </Route>
        <Route path="/:clientId">
          <Services />
          <Route path="/:clientId/visas" exact={true}>
            <Visas
                records={visaRecords}
                isLoading={visaIsLoading}
                fresh={freshVisas}
                canDownloadDocLackOfPassport={canDownloadDocLackOfPassport()}
            />
          </Route>
          <Route path="/:clientId/extensions" exact={true}>
            <Extensions records={extRecords} isLoading={extIsLoading} fresh={freshExt}
                        canCreateNextExtensionFor211Visa={canCreateNextExtensionFor211Visa}
                        nextExtensionFor211VisaParams={getNextExtensionFor211VisaParams}
                        canCreateVOAExtension={canCreateVOAExtension}
            />
          </Route>
          <Route path="/:clientId/create-voa" exact={true}>
            <CreateVOA />
          </Route>
          <Route path="/:clientId/deliveries" exact={true}>
            <Deliveries />
          </Route>
          <Route path="/:clientId/kitas" exact={true}>
            <Kitas />
            <ReportFiles />
          </Route>
          <Route path="/:clientId/review" exact={true}>
            <Review />
          </Route>
          <Route path="/:clientId/profile" exact={true}>
            <Profile />
          </Route>
        </Route>
      </Wrap>
      <Route path="/:clientId">
        <Footer />
      </Route>
      <Route path="/:clientId" exact={true}>
        <RedirectToVisas />
        {/* Main page */}
      </Route>
    </PageWrapper>
  );
}
