import React, { ReactElement } from 'react';
import {
  ModalWrapper,
  ModalWindow,
  ModalHeader,
  ModalTitle,
  ModalButtonClose,
  ModalBody,
} from './index.styles';

export default function Modal({
  show, title, onClose, isRawHtml, children,
}:{show:boolean, title?:string, onClose?: () => void, isRawHtml?:boolean, children:(string | ReactElement)}) {
  const onClick = () => {
    if (onClose) {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
      onClose();
    }
  };
  if (show) {
    document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
  }
  const onCloseModal = (e:React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as Element).classList.contains('modal-wrapper')) {
      onClick();
    }
  };
  if (show) {
    return (
      <ModalWrapper onClick={onCloseModal} className="modal-wrapper">
        <ModalWindow>
          <ModalHeader>
            <ModalTitle>
              {title}
            </ModalTitle>
            <ModalButtonClose onClick={onClick}>×</ModalButtonClose>
          </ModalHeader>
          <ModalBody>
            {isRawHtml && <div dangerouslySetInnerHTML={{ __html: children as string }} />}
            {!isRawHtml && children}
          </ModalBody>
          {/* <ModalFooter> */}
          {/*    <button className="green">Save</button> */}
          {/*    <button className="grey">Cancel</button> */}
          {/* </ModalFooter> */}
        </ModalWindow>
      </ModalWrapper>
    );
  }
  return (
    <>
    </>
  );
}
