import styled, {AnyStyledComponent} from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import { Button } from 'react-bootstrap';

export const PageWrapper = styled.div`
  
`;

export const HeaderWrapper = styled.header`
    background: #fff;
    border: 1px solid #e9ecef;
    border-top: none;
    height: 50px;
    overflow: hidden;
    @media only screen and (max-width: 414px){
      border-left: none;
      border-right: none;
    }
`;

export const NavBar = styled.nav`
    display: flex;
    flex-wrap: inherit;
    justify-content: space-between;
`;

export const NavBarLeftItems = styled(NavBar)`
  justify-content: start;
`;

export const NavBarRightItems = styled(NavBar)`
  justify-content: end;
`;

export const Wrap = styled.div`
    min-height: calc(100vh - 112px);
    z-index: auto;
`;

export const Card = styled.div<{isMobile?:boolean}>`
    margin-top: 2px;
    padding: 10px;
    background: #fff;
    border: 1px solid #ebebeb;
    @media only screen and (max-width: 414px){
      border-left: none;
      border-right: none;
    }
    ${(props) => (props.isMobile ? 'width:100%' : '')}
    label {
      &.required {
        &::after {
          content: '*';
          margin-left: 5px;
          color: #f82b60;
          fill: #f82b60;
        }
      }
    }
`;

export const CardHeader = styled.div`
    h3 {
      margin: 0;
      color: #04ccfc;
      font-weight: 400;
    }
    margin-bottom: 10px;
`;

export const CardBody = styled.div`
    //padding: 5px 10px;

`;

export const LogoMini = styled.img`
  display: flex;
  padding: 7px;
  max-height: 50px;
`;

export const ProfileLink = styled(Link)`
    display: flex;
    font-size: 25px;
    line-height: 50px;
    padding: 12px 15px 0 12px;
    text-decoration: none;
`;

export const ClientInfo = styled.div`
    font-size: 12px;
    line-height: 13px;
    margin-left: 5px;
    font-weight: 600;
    margin-top: -1px;
    text-transform: uppercase;
`;

export const ServicesWrapper = styled.section`
    background-color: #fff;
    padding: 10px 0;
    margin-top: 2px;
    border: 1px solid #ebebeb;
    @media only screen and (max-width: 414px){
      border-left: none;
      border-right: none;
    }
`;

export const ServicesHeader = styled.div`
    -webkit-text-decoration: none;
    text-decoration: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: stretch;
    padding: 0 15px 15px;
    h3 {
        line-height: 22px;
        font-weight: 400;
        color: #04ccfc;
        margin: 0;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    a {
        -webkit-text-decoration: none;
        text-decoration: none;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        text-align: right;
        font-size: 14px;
        line-height: 22px;
        color: #7F9799;
        margin: 0;
        text-decoration: underline;
        &:hover {
          color: #0a58ca;
        }
    }
`;

export const BreakLine = styled.div`
    margin-left: 16px;
    margin-right: 16px;
    border-bottom: 1px solid #d8dfe0;
`;

export const ServicesSliderWrapper = styled.div`
    padding: 10px 15px;
    max-width: 100%;
    overflow-x: auto;
    width: auto;
`;

export const ServicesSlider = styled.ul`
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
`;

export const ServicesSliderItem = styled.li`
    a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-text-decoration: none;
        text-decoration: none;
        //min-width: 95px;
        border: 1px solid #d8dfe0;
        border-radius: 3px;
        box-sizing: border-box;
        white-space: nowrap;
        cursor: pointer;
        label {
            color: #002F34;
            text-align: center;
            font-size: 16px;
            line-height: 18px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 5px 10px;
            height: 100%;
            cursor: pointer;
        }
        &.active {
            background-color: #04ccfc;
            label {
                color: #fff;
                cursor: default;
            }
            cursor: default;
        }
    }
`;

export const GoBack = styled.a`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  color: #04ccfc;
  padding: 10px 15px;
  text-decoration: underline;
`;

export const PageServicesWrapper = styled.div`
    h3 {
        line-height: 22px;
        font-weight: 400;
        color: #04ccfc;
        margin: 0;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 10px 15px;
    }
`;

export const ListOfServices = styled.div`
  padding: 0 15px;
`;

export const ServiceLink = styled(Link)`
    -webkit-box-align: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #d8dfe0;
    border-radius: 4px;
    color: rgb(0, 47, 52);
    cursor: pointer;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 16px;
    font-size: 16px;
    text-decoration: none;
    transition: background 0.3s ease 0s;
    width: 100%;
    @media (min-width: 415px){
        width: 200px;
        display: inline-flex;
        margin: 5px 5px 5px 0;
    }
    label {
      cursor: pointer;
    }
`;

export const ServiceIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
`;

export const TableWrapper = styled.div`
   // overflow: hidden;
   @media only screen and (max-width: 768px){
      overflow: auto;
   }
`;

export const Table = styled.table`
    position: relative;
    overflow: auto;
    width: 100%;
    thead {
        @media only screen and (max-width: 414px){
            display: none;
        }
    }
    tbody {
        position: relative;
    }
    tr {
       position: relative;
       display: block;
       margin-bottom: 10px;
       background-color: #f8f8f8;
       @media (min-width: 415px){
         display: table-row;
         &:nth-of-type(odd):not(th) {
           background-color: #fff;
         }
       }

    }
    th {
       position: relative;
       border: 1px solid #dee2e6;
       padding: 12px 10px;
       text-align: left;
       font-weight: 600;
       background-color: #f8f8f8;
       @media only screen and (max-width: 414px){
         display: none;
       }
    }
    td {
       position: relative;
       display: block;
       text-align: right;
       border: 1px solid #d8dfe0;
       padding: 5px 10px;
       //background-color: #f8f8f8;
       @media (min-width: 415px){
         display: table-cell;
         text-align: left;
       }
       &:before {
         display:flex;
         content: attr(data-label);
         position: absolute;
         left: 10px;
         font-weight: 600;
         @media (min-width: 415px){
           display: none;
         }
       }
       &:not(:last-child){
          border-bottom: none;
          @media (min-width: 415px){
            border-bottom: 1px solid #d8dfe0;
          }
       }
       &.caption {
          font-weight: 500;
          text-transform: uppercase;
          text-align: left;
          line-height: 1;
          padding: 10px;
          background-color: #e6e6e6;
          @media (min-width: 415px){
              display: none;
          }
       }
       
    }
`;

export const TdLabel = styled.div`
    display: block;
    position: absolute;
    top: 3px;
    font-weight: 600;
    text-align: left;
    &.two-lines {
      line-height: 14px;    
    }
    @media (min-width: 415px){
      display: none;
    }
`;

export const TableDataEmpty = styled.div`
  padding: 20px 10px;
  font-weight: 500;
  border: 1px solid #d8dfe0;
  text-align: center;
  @media (min-width: 415px){
    border-top: none;
  }
`;

export const Badge = styled.span`
    display: inline-block;
    padding: 3px 6px 2px;
    margin-left: 5px;
    margin-top: -3px;
    font-size: 80%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 2px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
`;

export const BadgeGreen = styled(Badge)`
    background-color: #28a745;
`;

export const BadgeRed = styled(Badge)`
    background-color: #dc3545;
`;

export const BadgeGrey = styled(Badge)`
    background-color: #6c757d;
`;

export const CreateNextExtensionButton = styled.a`
   color: #00ccff;
   text-decoration: underline;
   cursor: pointer;
`;

export const LinkCreateVOA = styled(Link)`
   color: #00ccff;
   text-decoration: underline;
   cursor: pointer;
`;

export const LinkDownload = styled.a`
   color: #00ccff;
   text-decoration: underline;
   cursor: pointer;
`;

export const ThumbnailImage = styled.img`
   max-width: 64px;
   max-height: 64px;
   border-radius: 2px;
   margin-right: 20px;
   cursor: pointer;
`;

export const ThumbnailIframe  = styled.iframe`
   max-width: 64px;
   max-height: 64px;
   border-radius: 2px;
   margin-right: 20px;
   cursor: pointer;
   overflow: hidden;
   pointer-events: none;
`;

export const LinkAdd = styled.a`
   margin-right: 20px;
   cursor: pointer;
   color: #00ccff;
   text-decoration: underline;
`;

export const LinkUpload = styled(LinkAdd)``;

export const LinkRemove = styled.a`
   cursor: pointer;
   color: #ff0000;
   text-decoration: underline;
`;

export const LinkModal = styled.a`
   display: flex;
   text-decoration: underline;
   //padding: ${(props) => props.theme.padding || '10px 0'};
   color: ${(props) => props.theme.color || '#00ccff'};
   cursor: pointer;
`;

export const ActionBar = styled.div`
   display: block;
   align-items: center;
`;

export const ModalWrapper = styled.div`
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 99999;
   padding: 10px;
   overflow: auto;
   &::after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999999;
      background: rgba(0,0,0,0.2);
   }
   a {
     color: #00ccff;
     text-decoration: underline;
   }
`;
export const ModalWindow = styled.div`
   position: relative;
   left: auto;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   width: 100%;
   pointer-events: auto;
   background-color: #fff;
   background-clip: padding-box;
   border-radius: 2px;
   border: 1px solid #e9ecef;
   outline: 0;
   z-index: 9999999;
   max-width: 700px;
   margin-left: auto;
   margin-right: auto;
`;
export const ModalHeader = styled.div`
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: start;
   -ms-flex-align: start;
   align-items: flex-start;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   padding: 10px;
   border-bottom: 1px solid #e9ecef;
   border-top-left-radius: 2px;
   border-top-right-radius: 2px;
`;
export const ModalTitle = styled.div`
    margin-bottom: 0;
    line-height: 1.6;
`;
export const ModalButtonClose = styled.button`
   float: right;
   font-size: 1.5rem;
   font-weight: 700;
   line-height: 1;
   color: #000;
   text-shadow: 0 1px 0 #fff;
   opacity: .5;
   cursor: pointer;
   background-color: transparent;
   border: 0;
   -webkit-appearance: none;
`;
export const ModalBody = styled.div`
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 10px;
    text-align: left;
;
`;
export const ModalFooter = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid #e9ecef;
    button {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 5px 15px;
        margin-right: 10px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 2px;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        &.red {
            color: #fff;
            background-color: #dc3545;
        }
        &.green {
            color: #fff;
            background-color: #28a745;
        }
        &.grey {
            color: #fff;
            background-color: #6c757d;
        }
    }
`;

export const FileUploadLabel = styled.label`
   position: relative;
   cursor: pointer;
   color: #00ccff;
   text-decoration: underline;
   input {
     position: absolute;
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
     opacity: 0;
     z-index: -1;
   }
   img {
      max-width: 100px;
   }
   &:hover {
      color: #0a58ca;
   }
`;

export const LinkPopover = styled.a`
   display: inline-block;
   text-decoration: underline;
   padding: ${(props) => props.theme.padding || '10px 0'};
   color: ${(props) => props.theme.color || '#00ccff'};
   cursor: pointer;
`;

export const PopoverWrapper = styled.div`
   position: absolute;
   left: 10px;
   right: 10px;
   display: block;
   padding: 20px 10px 10px;
   z-index: 10000;
   font-style: normal;
   line-height: 1.5;
   text-align: left;
   text-decoration: none;
   text-shadow: none;
   text-transform: none;
   letter-spacing: normal;
   word-break: normal;
   word-spacing: normal;
   white-space: normal;
   line-break: auto;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #d8dfe0;
   border-radius: 2px;
   max-width: 320px;
   .arrow {
      position: absolute;
      display: block;
      width: 16px;
      height: 8px;
      margin-left: -8px;
      left: 50%;
      top: calc(-8px - 1px);
      ::after, 
      ::before {
          position: absolute;
          display: block;
          content: "";
          border-color: transparent;
          border-style: solid;
      }
      ::before {
         top: 0;
         border-width: 0 8px 8px 8px;
         border-bottom-color: #d8dfe0;
      }
      ::after {
          top: 1px;
          border-width: 0 8px 8px 8px;
          border-bottom-color: #fff;
      }
   }
   .close {
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      padding: 3px 12px;
      z-index: 100000;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      -webkit-appearance: none;
   }
   a {
      cursor: pointer;
      color: #00ccff;
      text-decoration: underline;
   }
`;

export const PaymentsWrapper = styled.div`
   &.pay-for-all {
      margin-top: 10px;
   }
`;

export const PaymentTypeWrapper = styled.div`
  .dropdown {
    display: block;
    margin-bottom: 5px;
    button {
      padding: 3px 15px;
      border-radius: 2px;
      background: transparent !important;
      color: #333333 !important;
      border: 2px solid #d8dfe0 !important;
      outline: none;
    }
  }
`;

export const ButtonPay = styled.button`
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 2px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #28a745;
    border: 1px solid #28a745;
    min-width: 130px;
    &:hover {
      opacity: 0.8;
    }
   // animation: pulse 1.5s infinite;
   // @keyframes pulse {
   //   0% {
   //    box-shadow: 0 0 0 0 rgba(40,167,69, 0.7);
   //   }
   //   
   //   70% {
   //    box-shadow: 0 0 0 10px rgba(40,167,69, 0);
   //   }
   //   
   //   100% {
   //    box-shadow: 0 0 0 0 rgba(40,167,69, 0);
   //   }
   //}
`;

export const WeAccept = styled.div`
    display: inline;
    margin-top: 5px;
    padding: 0 17px;
    vertical-align: middle;
    img {
      max-width: 48px;
      max-height: 30px;
      margin-right: 5px;
      vertical-align: middle;
    }
`;

export const DocLackOfPassportWrapper = styled.div`
  margin-top: 10px;
`;

export const ButtonPayNow = styled(ButtonPay)`
   padding: 2px 10px;
   min-width: auto;
   font-size: 16px;
`;

export const PriceWripper = styled.div`
    white-space: nowrap;
`;

export const Price = styled(NumberFormat as unknown as AnyStyledComponent)`
   @media (min-width: 415px){
       line-height: 30px;
       margin-right: 10px;
   }
`;

export const InfoIcon = styled(FontAwesomeIcon)`
  path {
    color: #00ccff;
  }
`;

export const PaidIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-bottom: -1px;
  path {
    color: #28a745;
  }
  @media only screen and (max-width: 414px){
    margin-left: 5px;
  }
`;

export const FooterWrapper = styled.div`
  margin-top: 2px;
  border-top: 1px solid #e9ecef;
  padding: 15px 0 10px;
  a {
    color: #00ccff;
    text-decoration: none;
  }
  img {
    width: 30px;
    margin-left: 10px;
  }
  .contact-us {
    text-align: right;
  }
  @media only screen and (max-width: 414px){
      .contact-us {
        margin-top: 10px;
        text-align: left;
      }
  }
`;

export const VaccinationShow = styled.label`
    margin-right: 20px;
    color: #00ccff;
    text-decoration: underline;
    cursor: pointer;
`;

export const AsLink = styled.span`
    color: #00ccff;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: #0a58ca;
    }
`;
export const VisaDate = styled.div`
  font-size: 80%;
  font-weight: 500;
  color: #333;
`;

export const MButtonCopy = styled(Button)`
  line-height: 1;
  height: 30px;
  margin: 8px 5px 0 -5px;
`;

export const SponsorCompany = styled.div`
  font-size: 80%;
`;

export const Input = styled.input`
  display: inline-block;
  max-width: 135px;
`;

export const HintRed = styled.small`
  display: inline-block;
  color: red;
  font-weight: 600;
  line-height: 14px;
  margin-top: 5px;
`;

export const OrderStatusDefault = styled.span`
  padding: 5px 10px;
  border-radius: 4px;
  color: #000000;
  white-space: nowrap;
`;

export const OrderStatusNew = styled(OrderStatusDefault)`
  background-color: #cfdfff;
`;

export const OrderStatusPaid = styled(OrderStatusDefault)`
  background-color: #c2f5e9;  
`;

export const OrderStatusImmigrationProcessing = styled(OrderStatusDefault)`
  background-color: #ffd66e;
`;

export const OrderStatusVisaIsReady = styled(OrderStatusDefault)`
  background-color: #20c933;
  color: #fff;
`;

export const OrderStatusRefund = styled(OrderStatusDefault)`
  background-color: #fee2d5;
`;

export const OrderStatusFailed = styled(OrderStatusDefault)`
  background-color: #f82b60;
  color: #fff;
`;

export const OrderStatusesDescription = styled.div`
  margin: 10px;
  * {
    font-size: 85%;
  }
`;

export const OrderStatusDescription = styled.span`
  margin: 5px 5px 5px 0;
  @media only screen and (max-width: 414px){
    display: block;
    margin: 5px 5px 5px 0;
  }
`;

export const ExtensionStatusDefault = styled.span`
  padding: 5px 10px;
  border-radius: 4px;
  color: #000000;
  white-space: nowrap;
`;

export const ExtensionStatusNew = styled(ExtensionStatusDefault)`
  background-color: #d0f0fd;
`;

export const ExtensionStatusGetPassport = styled(ExtensionStatusDefault)`
  background-color: #c2f5e9;  
`;

export const ExtensionStatusDocsPreparing = styled(ExtensionStatusDefault)`
  background-color: #ffeab6;
`;

export const ExtensionStatusDocsReady = styled(ExtensionStatusDefault)`
  background-color: #93e088;
`;

export const ExtensionStatusInImmigration = styled(ExtensionStatusDefault)`
  background-color: #ffeab6;
`;

export const ExtensionStatusReturned = styled(ExtensionStatusDefault)`
  background-color: #cdb0ff;
`;

export const ExtensionStatusDone = styled(ExtensionStatusDefault)`
  background-color: #d1f7c4;
`;

export const ExtensionStatusFailed = styled(ExtensionStatusDefault)`
  background-color: #f82b60;
  color: #fff;
`;

export const ExtensionStatusesDescription = styled.div`
  margin: 10px;
  * {
    font-size: 85%;
  }
`;

export const ExtensionStatusDescription = styled.span`
  margin: 5px 5px 5px 0;
  @media only screen and (max-width: 414px){
    display: block;
    margin: 5px 5px 5px 0;
  }
`;

export const ThankYou = styled.div`
  padding: 40px 0;
  margin: auto;
  text-align: center;
  font-size: 20px;
`;

export const ChoosePaymentTypeTip = styled.div`
  display: inline-flex;
  background-color: #e1ecf4;
  font-size: 85%;
  padding: 1px 5px;
  border-radius: 2px;
  color: #245980;
  white-space: nowrap;
  margin-bottom: 5px;
`;

export const BioKitasWrapper = styled.div`
  .input-group {
      display: inline;
      input {
        min-width: 100px;
      }
  }
`;
