import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Preloader } from './Preloader';
import config from '../../config';
import {
  Card,
  CardBody,
  CardHeader, Price, PriceWripper,
  Table,
  TableWrapper,
  TableDataEmpty,
} from './index.styles';
import { formattedDateString } from './helper';

function PassportLocation({ clientId }:{ clientId: string | undefined }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (clientId) {
      axios
        .get(`${config.api.url}clients/${clientId}/passport-location`)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [clientId]);
  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }
  return (
    <Card>
      <div className="passport-location">
        {`${t('passport_location')}:`}
        <span>
          {`${data.passport_location} (${formattedDateString(data.last_modified_time, 'DD.MM.YYYY')})` || '—'}
        </span>
      </div>
    </Card>
  );
}

export default function Deliveries() {
  const { t } = useTranslation();
  const { clientId } = useParams<{clientId:string}>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<any>>([]);
  useEffect(() => {
    if (clientId) {
      axios
        .get(`${config.api.url}clients/${clientId}/deliveries`)
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [clientId]);

  if (isLoading) {
    return <Preloader isLoading={isLoading} />;
  }
  return (
    <>
      <PassportLocation clientId={clientId} />
      <Card>
        <CardHeader>
          <h3>
            {t('your_deliveries')}
          </h3>
        </CardHeader>
        <CardBody>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>{t('deliveries_date_time')}</th>
                  <th>{t('deliveries_type')}</th>
                  <th>{t('deliveries_what')}</th>
                  <th>{t('deliveries_amount')}</th>
                  {/* <th>{t('deliveries_paid_to')}</th> */}
                  <th>{t('deliveries_status')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((record) => {
                  const recordId = record.id;
                  const { fields } = record;
                  let hidePrice = false;
                  if (fields.What.length === 1 && fields.What[0] === 'Passport') {
                    hidePrice = true;
                  }
                  return (
                    <tr key={recordId}>
                      <td data-label={t('deliveries_date_time')}>
                        {formattedDateString(fields.Date, 'DD.MM')}
                        /
                        {fields.Time}
                      </td>
                      <td data-label={t('deliveries_type')}>
                        {fields.Type}
                      </td>
                      <td data-label={t('deliveries_what')}>
                        {fields.What && fields.What
                          .map((what:string) => <span key={what}>{what}</span>)
                          .reduce((prev: any, curr: any) => [prev, ', ', curr])}
                      </td>
                      <td data-label={t('deliveries_amount')}>
                        {!hidePrice && fields.Total && (
                          <PriceWripper>
                            <Price
                              value={fields.Total[0] as number}
                              allowEmptyFormatting
                              displayType="text"
                              thousandSeparator
                              suffix=" IDR"
                            />
                          </PriceWripper>
                        )}
                        {(hidePrice || !fields.Total) && '—'}
                      </td>
                      <td data-label={t('deliveries_status')}>
                        {fields.Status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableWrapper>
          {!data.length
                    && (
                      <TableDataEmpty>
                        {t('table_data_not_found')}
                      </TableDataEmpty>
                    )}
        </CardBody>
      </Card>
    </>
  );
}
