import React, {ChangeEvent, useEffect, useState} from 'react';
import axios from 'axios';
import {Trans, useTranslation} from 'react-i18next';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {Preloader} from '../Preloader';
import {
    PaidIcon,
    Card,
    CardBody,
    CardHeader,
    TableWrapper,
    Table,
    ButtonPayNow,
    PaymentsWrapper,
    LinkModal as LinkModalHowTo,
    LinkModal as LinkModalFaq,
    ActionBar,
    PriceWripper,
    Price,
    TableDataEmpty,
    CreateNextExtensionButton,
    Input,
    HintRed,
    ExtensionStatusNew,
    ExtensionStatusFailed,
    ExtensionStatusDefault,
    ExtensionStatusesDescription,
    ExtensionStatusDescription,
    ExtensionStatusGetPassport,
    ExtensionStatusDocsPreparing,
    ExtensionStatusDocsReady,
    ExtensionStatusInImmigration,
    ExtensionStatusReturned,
    ExtensionStatusDone, PaymentTypeWrapper, ChoosePaymentTypeTip, LinkCreateVOA,
} from '../index.styles';
import config from '../../../config';
import {formattedDateString, getUrlFromField} from '../helper';
import {default as ModalHowTo, default as ModalFaq} from '../Modal';
import ThumbnailViewer from '../ThumbnailViewer';
import {PreloaderState} from '../PreloaderState';
import CollapseList from '../CollapseList';
import {IExtensions} from "../../../hooks/useExtensions";
import {useParams} from "react-router-dom";
// import VisaImg from "./images/visa.svg";
// import MastercardImg from "./images/mastercard.svg";

// function BioTalonBlock({extensionId, bioTalonLink}: { extensionId: string, bioTalonLink: string }) {
//     const {t} = useTranslation();
//     const params = {
//         table: 'Extensions',
//         column: 'BioTalonLink',
//         recordId: extensionId,
//         bucket: 'BioTalon',
//     };
//     const [imageSrc, setImageSrc] = useState(bioTalonLink !== undefined ? bioTalonLink : '');
//     const [action, setAction] = useState(imageSrc !== '' ? 'remove' : 'add');
//     const [isLoading, setIsLoading] = useState<boolean>(false);
//     const handleRemove = () => {
//         setIsLoading(true);
//         axios
//             .delete(`${config.api.url}extensions/${extensionId}/remove-bio-talon`)
//             .then((res) => {
//                 setAction('add');
//                 setIsLoading(false);
//             })
//             .catch((error) => {
//                 console.log(error);
//                 setIsLoading(false);
//             });
//     };
//     const handleUploaded = (fileSrc: string) => {
//         setImageSrc(fileSrc);
//         setAction('remove');
//     };
//     if (action === 'add') {
//         return (
//             <ActionBar key={imageSrc}>
//                 <FileUpload params={params} onUploaded={handleUploaded}/>
//             </ActionBar>
//         );
//     }
//     if (!imageSrc) {
//         return (
//             <>
//                 —
//             </>
//         );
//     }
//     return (
//         <ActionBar key={imageSrc}>
//             <ThumbnailViewer img={imageSrc}/>
//             {!isLoading
//                 && (
//                     <LinkRemove onClick={handleRemove}>
//                         {t('delete') as string}
//                     </LinkRemove>
//                 )}
//             {isLoading
//                 && <PreloaderState isLoading/>}
//         </ActionBar>
//     );
// }

// function QrPhotoBlock({extensionId, qrPhotoLink}: { extensionId: string, qrPhotoLink: string }) {
//     const params = {
//         table: 'Extensions',
//         column: 'QrPhotoLink',
//         recordId: extensionId,
//         bucket: 'QrPhotos',
//     };
//     const [imageSrc, setImageSrc] = useState(qrPhotoLink !== undefined ? qrPhotoLink : '');
//     const [action, setAction] = useState(imageSrc !== '' ? 'remove' : 'add');
//     const [isLoading] = useState<boolean>(false);
//     // const handleRemove = () => {
//     //     setIsLoading(true);
//     //     axios
//     //         .delete(`${config.api.url}extensions/${extensionId}/remove-qr-photo`)
//     //         .then((res) => {
//     //             setAction('add');
//     //             setIsLoading(false);
//     //         })
//     //         .catch((error) => {
//     //             console.log(error);
//     //             setIsLoading(false);
//     //         });
//     // };
//     const handleUploaded = (fileSrc: string) => {
//         setImageSrc(fileSrc);
//         setAction('remove');
//     };
//     if (action === 'add') {
//         return (
//             <ActionBar key={imageSrc}>
//                 <FileUpload params={params} onUploaded={handleUploaded}/>
//             </ActionBar>
//         );
//     }
//     if (!imageSrc) {
//         return (
//             <>
//                 —
//             </>
//         );
//     }
//     return (
//         <ActionBar key={imageSrc}>
//             <ThumbnailViewer img={imageSrc}/>
//             {/* {!isLoading */}
//             {/* && ( */}
//             {/*   <LinkRemove onClick={handleRemove}> */}
//             {/*     {t('delete')} */}
//             {/*   </LinkRemove> */}
//             {/* )} */}
//             {isLoading
//                 && <PreloaderState isLoading/>}
//         </ActionBar>
//     );
// }

function BioCompleteDate({extensionId, bioComplete}: { extensionId: string, bioComplete: any }) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bioDate, setBioDate] = useState(bioComplete !== undefined ? bioComplete : '');
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        if (e.target.value) {
            setBioDate(e.target.value);
        }
    };
    useEffect(() => {
        if (bioDate !== '') {
            const data = {
                bio_date: bioDate,
            };
            axios
                .post(`${config.api.url}extensions/${extensionId}/save-bio-date`, data)
                .then((res) => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [bioDate, extensionId]);
    if (isLoading) {
        return (
            <PreloaderState isLoading/>
        );
    }
    return (
        <>
            <Input type="date" max={moment().format('YYYY-MM-DD')} value={bioDate} onChange={handleOnChange}
                   className="form-control"/>
            {!bioDate
                && (
                    <div>
                        <HintRed><Trans i18nKey="bio_complete_hint"/></HintRed>
                    </div>
                )}
        </>
    );
}

function ExtPhotoBlock({extensionId, extPhotoLink}: { extensionId: string, extPhotoLink: string }) {
    // // const { t } = useTranslation();
    // // const params = {
    // //     table: 'Extensions',
    // //     column: 'ExtPhotoLink',
    // //     recordId: extensionId,
    // //     bucket: 'ExtPhoto',
    // };
    const [imageSrc] = useState(extPhotoLink !== undefined ? extPhotoLink : '');
    //    const [action, setAction] = useState(imageSrc !== "" ? 'remove' : 'add');
    //    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const handleRemove = () => {
    //     setIsLoading(true);
    //     axios
    //     .delete(`${config.api.url}extensions/${extensionId}/remove-ext-photo`)
    //     .then((res) => {
    //         setAction('add');
    //         setIsLoading(false);
    //     })
    //     .catch(error => {
    //         console.log(error);
    //         setIsLoading(false);
    //     });
    // }
    // const handleUploaded = (fileSrc:string) => {
    //     setImageSrc(fileSrc);
    //     setAction('remove');
    // }
    // if(action === 'add'){
    //     return (
    //         <ActionBar key={imageSrc}>
    //             <FileUpload params={params} onUploaded={handleUploaded}/>
    //         </ActionBar>
    //     );
    // }
    //
    if (!imageSrc) {
        return (
            <>
                —
            </>
        );
    }
    return (
        <ActionBar key={imageSrc}>
            <ThumbnailViewer img={imageSrc}/>
            {/* {!isLoading && */}
            {/* <LinkRemove onClick={handleRemove}> */}
            {/*    {t('delete')} */}
            {/* </LinkRemove>} */}
            {/* {isLoading && */}
            {/* <PreloaderState isLoading={true}/> */}
            {/* } */}
        </ActionBar>
    );
}

interface IPurchase {
    entryVisaId: string,
    clientRecordId: string,
    clientEmail: string,
    extensions: Array<string>
}

function PayType({
                     extensionId,
                     paidTo,
                     showPayButton,
                     onChangeType,
                 }: {
    extensionId: string,
    paidTo?: string,
    showPayButton: (show: boolean) => void,
    onChangeType: (type: string) => void,
}) {
    const {t} = useTranslation();
    const paymentTypes = [
        {key: 'Xendit', value: 'Online'},
        {key: 'Altapay', value: 'Crypto'},
        {key: 'Cash', value: 'Cash'},
    ];
    const defaultType = paidTo ? paymentTypes.filter((type) => type.key === paidTo)[0].value : t('payment_type');
    const [payType, setPayType] = useState(defaultType);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleClick = (type: any) => {
        setIsLoading(true);
        showPayButton(false);
        axios
            .post(`${config.api.url}extensions/${extensionId}/payment-type`, {type: type.key})
            .then((res) => {
                // console.log(res);
                setIsLoading(false);
                setPayType(type.value);
                onChangeType(type.value);
                showPayButton(type.value !== 'Cash');
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setPayType(payType);
            });
    };
    useEffect(() => {
        showPayButton((!(!paidTo || paidTo === 'Cash')));
    }, [paidTo, showPayButton]);
    if (isLoading) {
        return (
            <PaymentTypeWrapper>
                <PreloaderState isLoading/>
            </PaymentTypeWrapper>
        );
    }
    return (
        <PaymentTypeWrapper>
            <ChoosePaymentTypeTip>{t('choose_payment_type') as string}</ChoosePaymentTypeTip>
            <DropdownButton
                title={payType}
                size="sm"
            >
                {paymentTypes.map((type) => <Dropdown.Item key={`type-${type.key}`} onClick={() => {
                    handleClick(type);
                }}>{type.value}</Dropdown.Item>)}
            </DropdownButton>
        </PaymentTypeWrapper>
    );
}

function PayButton({purchase, buttonName, cssClass}: { purchase: object, buttonName: string, cssClass?: string }) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleOnClick = () => {
        setIsLoading(true);
        axios
            .post(`${config.api.url}invoices/extensions`, purchase)
            .then((res) => {
                window.location.href = res.data.invoiceUrl;
                // setIsLoading(true);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };
    if (isLoading) {
        return (
            <PaymentsWrapper className={cssClass}>
                <PreloaderState isLoading/>
            </PaymentsWrapper>
        );
    }
    return (
        <PaymentsWrapper className={cssClass}>
            <ButtonPayNow onClick={handleOnClick}>
                <Trans i18nKey={buttonName}/>
            </ButtonPayNow>
            {/* <WeAccept> */}
            {/*    <img src={VisaImg} alt="Visa"/> */}
            {/*    <img src={MastercardImg} alt="MasterCard"/> */}
            {/* </WeAccept> */}
        </PaymentsWrapper>
    );
}

function CreateNextExtension({params, fresh}: { params: any, fresh?: () => void }) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {t} = useTranslation();
    const onClick = () => {
        setIsLoading(true);
        axios
            .post(`${config.api.url}/extensions/create-next`, params)
            .then((res) => {
                if (fresh) {
                    fresh();
                }
                // setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    if (isLoading) {
        return (
            <PreloaderState isLoading/>
        );
    }
    return (
        <CreateNextExtensionButton onClick={onClick}>
            {t('create_next_extension') as string}
        </CreateNextExtensionButton>
    );
}

export function RenderExtensionStatus({name}: { name: string }): any {
    switch (name) {
        case 'New': {
            return (
                <ExtensionStatusNew>{name}</ExtensionStatusNew>
            );
        }
        case 'Get passport': {
            return (
                <ExtensionStatusGetPassport>{name}</ExtensionStatusGetPassport>
            );
        }
        case 'Docs preparing': {
            return (
                <ExtensionStatusDocsPreparing>Docs preparing</ExtensionStatusDocsPreparing>
            );
        }
        case 'Docs ready': {
            return (
                <ExtensionStatusDocsReady>{name}</ExtensionStatusDocsReady>
            );
        }
        case 'In immigration': {
            return (
                <ExtensionStatusInImmigration>{name}</ExtensionStatusInImmigration>
            );
        }
        case 'Returned': {
            return (
                <ExtensionStatusReturned>{name}</ExtensionStatusReturned>
            );
        }
        case 'Done': {
            return (
                <ExtensionStatusDone>{name}</ExtensionStatusDone>
            );
        }
        case 'Failed': {
            return (
                <ExtensionStatusFailed>{name}</ExtensionStatusFailed>
            );
        }
        default: {
            return (
                <ExtensionStatusDefault>{name}</ExtensionStatusDefault>
            );
        }
    }
}

export function RenderExtensionStatusesDescription() {
    const {t} = useTranslation();
    const statuses = [
        {name: 'New', description: t('status_new')},
        // { name: 'Get passport', description: t('status_get_passport') },
        {name: 'Docs preparing', description: t('status_docs_preparing')},
        {name: 'Docs ready', description: t('status_docs_ready')},
        {name: 'In immigration', description: t('status_in_immigration')},
        {name: 'Returned', description: t('status_returned')},
        {name: 'Done', description: t('status_done')},
        {name: 'Failed', description: t('status_failed')},
    ];
    return (
        <ExtensionStatusesDescription>
            {statuses.map((status) => (
                <ExtensionStatusDescription key={`extension-status-description-${status.name}`}>
                    <RenderExtensionStatus key={`render-extension-status-${status.name}`} name={status.name}/>
                    {' '}
                    {status.description}
                </ExtensionStatusDescription>
            ))}
        </ExtensionStatusesDescription>
    );
}

export default function Extensions({
   records,
   isLoading,
   fresh,
   canCreateNextExtensionFor211Visa,
   nextExtensionFor211VisaParams,
   canCreateVOAExtension,
}: {
    records: Array<IExtensions>,
    isLoading: boolean,
    fresh?: () => void,
    canCreateNextExtensionFor211Visa: () => boolean,
    nextExtensionFor211VisaParams: () => object,
    canCreateVOAExtension: () => boolean,
}) {
    const {clientId} = useParams<{ clientId: string }>();
    const {t} = useTranslation();
    const [showPayButton, setShowPayButton] = useState<boolean>(false);
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [toggleModalFaq, setToggleModalFaq] = useState<boolean>(false);
    let prevCaption = '';
    let purchaseForAll: IPurchase = {} as IPurchase;
    const extensionIds: Array<string> = [];
    const onClickModalHowTo = () => {
        setToggleModal(!toggleModal);
    };
    const onClickModalFaq = () => {
        setToggleModalFaq(!toggleModalFaq);
    };
    const handleShowPayButton = (show: boolean) => {
        setShowPayButton(show);
    };
    const handleChangePayType = (type: string) => {
        if (fresh) {
            fresh();
        }
    };
    if (isLoading) {
        return <Preloader isLoading={isLoading}/>;
    }
    return (
        <>
            <Card>
                <CardHeader>
                    <h3>
                        {t('your_extensions') as string}
                        <LinkModalFaq onClick={onClickModalFaq} style={{float: 'right', marginTop: '5px'}}>
                            {t('link_modal_faq') as string}
                        </LinkModalFaq>
                    </h3>
                    <ModalFaq show={toggleModalFaq} onClose={onClickModalFaq} title={t('link_modal_faq') as string}>
                        <CollapseList key="faq" list={t('faq', {returnObjects: true})}/>
                    </ModalFaq>
                </CardHeader>
                <CardBody>
                    <TableWrapper>
                        <Table>
                            <thead>
                            <tr>
                                <th>
                                    {t('extension_type') as string}
                                </th>
                                <th>{t('price') as string}</th>
                                <th>{t('status') as string}</th>
                                <th>{t('qr') as string}</th>
                                <th>{t('last_day') as string}</th>
                                {/* <th>{t('bio_talon') as string}</th> */}
                                <th>{t('bio_complete') as string}</th>
                                <th>{t('ext_photo') as string}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {records.map((record, index) => {
                                const recordId = record.id;
                                const {fields} = record;
                                const clientRecordId = fields.ClientRecordID[0];
                                const caption = `${t('visa')} ${formattedDateString(fields['Created (from 211Contract)'][0] as string)}`;
                                const showCaption = prevCaption !== caption;
                                prevCaption = caption;
                                const paid = !!fields.Paid;
                                const entryVisaId = fields['211Contract'][0];
                                const purchase = {
                                    entryVisaId,
                                    clientRecordId,
                                    clientEmail: fields['Email (from Client) (from 211Contract)'][0],
                                    extensions: [recordId],
                                    paidTo: fields.PaidTo,
                                };
                                index += 1;
                                const effLastDay = fields.EffLastDay && records.length === index ? fields.EffLastDay[0] : undefined;
                                const qrPhotoLink = fields['QR-photo'] ? getUrlFromField(fields['QR-photo']) : fields.QrPhotoLink;
                                if (!paid) {
                                    if (Object.keys(purchaseForAll).length === 0) {
                                        purchaseForAll = {...purchase};
                                    }
                                    extensionIds.push(recordId);
                                    purchaseForAll.extensions = extensionIds;
                                }
                                return (
                                    <tr key={recordId}>
                                        {showCaption
                                            && (
                                                <td className="caption">
                                                    {caption}
                                                </td>
                                            )}
                                        <td data-label={t('extension_type') as string}>
                                            {fields.Type}
                                        </td>
                                        <td data-label={t('price') as string}>
                                            <PriceWripper>
                                                <Price
                                                    value={fields.Total as number}
                                                    allowEmptyFormatting
                                                    displayType="text"
                                                    thousandSeparator
                                                    suffix=" IDR"
                                                />
                                                {paid && <PaidIcon icon={faCheckCircle}/>}
                                                {/* {paid && <BadgeGreen>{t('paid')}</BadgeGreen>} */}
                                                {!paid && <PayType key={`pay-type-${recordId}`} extensionId={recordId}
                                                                   paidTo={fields.PaidTo}
                                                                   showPayButton={handleShowPayButton}
                                                                   onChangeType={handleChangePayType}/>}
                                                {!paid && showPayButton &&
                                                    <PayButton purchase={purchase} buttonName="pay_now"/>}
                                            </PriceWripper>
                                        </td>
                                        <td data-label={t('status') as string}>
                                            <RenderExtensionStatus name={fields.Status}/>
                                        </td>
                                        <td data-label={t('qr')}>
                                            {qrPhotoLink && <ThumbnailViewer img={qrPhotoLink}/>}
                                            {/* <QrPhotoBlock extensionId={recordId} qrPhotoLink={fields.QrPhotoLink as string} /> */}
                                            {fields.QrPhotoLink && fields['Imm office']
                                                && (
                                                    <>
                                                        <LinkModalHowTo onClick={onClickModalHowTo}>
                                                            {t('link_modal_how_to') as string}
                                                        </LinkModalHowTo>
                                                        <ModalHowTo show={toggleModal} onClose={onClickModalHowTo}
                                                                    isRawHtml title={t('link_modal_how_to') as string}>
                                                            {t('modal_how_to', {returnObjects: true})}
                                                        </ModalHowTo>
                                                    </>
                                                )}
                                             {!fields['QR-photo'] && '—'}
                                        </td>
                                        <td data-label={t('last_day')}>
                                            {effLastDay !== undefined && formattedDateString(effLastDay)}
                                            {effLastDay === undefined && '—'}
                                        </td>
                                        {/* <td data-label={t('bio_talon')}> */}
                                        {/*  <BioTalonBlock extensionId={recordId} bioTalonLink={fields.BioTalonLink as string} /> */}
                                        {/* </td> */}
                                        <td data-label={t('bio_complete')}>
                                            {(fields.Status === 'In immigration' && ['211A-1', 'VOA', 'D1-1', 'D1-3', 'D2-1', 'D2-3', 'D12-1'].indexOf(fields.Type) !== -1) && (
                                                <BioCompleteDate extensionId={recordId}
                                                                 bioComplete={fields.BioComplete}/>
                                                )}
                                            {fields.Status !== 'In immigration' && fields.BioComplete !== undefined && formattedDateString(fields.BioComplete)}
                                            {fields.Status !== 'In immigration' && fields.BioComplete === undefined && '—'}
                                        </td>
                                        <td data-label={t('ext_photo')}>
                                            <ExtPhotoBlock extensionId={recordId}
                                                           extPhotoLink={fields.ExtPhotoLink as string}/>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </TableWrapper>
                    {!records.length
                        && (
                            <TableDataEmpty>
                                {t('table_data_not_found') as string}
                            </TableDataEmpty>
                        )}
                    {Object.keys(purchaseForAll).length > 0 && Object.keys(purchaseForAll.extensions).length > 1 &&
                        <PayButton purchase={purchaseForAll} buttonName="pay_for_all" cssClass="pay-for-all"/>}
                    {canCreateNextExtensionFor211Visa() && <><CreateNextExtension params={nextExtensionFor211VisaParams()}
                                                                     fresh={fresh}/><br/></>}
                    {canCreateVOAExtension() && <LinkCreateVOA to={`/${clientId}/create-voa`}>{t('create_voa') as string}</LinkCreateVOA>}
                </CardBody>
            </Card>
            <RenderExtensionStatusesDescription/>
        </>
    );
}
