import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;
const Collapse = styled.div``;
const Link = styled.a`
    display: flex;
    text-decoration: underline;
    color: #00ccff;
    cursor: pointer;
    margin-bottom: 5px;
    &.selected {
        color: #333333;
    }
`;
const Text = styled.div`
    display: none;
    &.collapsed {
        display: flex;
        margin: 5px 0 10px;
        background: #fafafa;
        padding: 5px;
        border-radius: 2px;
        border: 1px solid #ebebeb;
    }
`;

interface ICollapseList {
    title: string,
    text: string
}

export default function CollapseList({ list }:{list:ICollapseList[]}) {
  const [activeTitle, setActiveTitle] = useState<any>('');
  // eslint-disable-next-line react/no-unstable-nested-components
  function CollapseLink({ isSelected, children }:{isSelected: boolean, children:any}) {
    const onClick = () => {
      setActiveTitle(activeTitle !== children ? children : '');
    };
    return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link onClick={onClick} className={isSelected ? 'selected' : ''}>
        {children}
      </Link>
    );
  }
  return (
    <Wrapper>
      {list.map((item) => (
        <Collapse key={item.title}>
          <CollapseLink isSelected={activeTitle === item.title}>
            {item.title}
          </CollapseLink>
          <Text className={activeTitle === item.title ? 'collapsed' : ''}>
            <div dangerouslySetInnerHTML={{__html: item.text as string}} />
          </Text>
        </Collapse>
      ))}
    </Wrapper>
  );
}
