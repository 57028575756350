import React, {useCallback, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import getUserLocale from 'get-user-locale';
import {
  LanguagesWrapper,
  CurrentItem,
  ListItems,
  ListItem,
} from './index.styles';
import config from '../../config';

const options = [
  {
    lang: 'ru',
    title: 'RU',
    icon: 'flag-icon-ru',
  },
  {
    lang: 'en',
    title: 'EN',
    icon: 'flag-icon-gb',
  },
  // {
  //     lang: "es",
  //     title: "ES",
  //     icon: "flag-icon-es"
  // },
];

function setLanguage(value:string) {
  localStorage.setItem(`language_${config.secretKey}`, value);
}

function getLanguage() {
  const userLang = getUserLocale();
  let defaultLang = config.languages.default;
  if (userLang === 'ru') {
    defaultLang = userLang;
  }
  return localStorage.getItem(`language_${config.secretKey}`) || defaultLang;
}

const defaultOption = options.filter((option) => option.lang === getLanguage())[0];

export default function Languages() {
  // const defaultLang = getLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const toggling = () => setIsOpen(!isOpen);
  const { i18n } = useTranslation();
  const changeLanguage = useCallback((lang:string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [i18n]);
  const onOptionClicked = (option:any) => () => {
    setSelectedOption(option);
    changeLanguage(option.lang);
    setIsOpen(false);
  };
  const filtered = options
    .filter((option) => option.lang !== selectedOption.lang);
  useEffect(() => {
    const localLang = localStorage.getItem(`language_${config.secretKey}`);
    if (!localLang) {
      const userLang = getUserLocale();
      let defaultLang = config.languages.default;
      if (userLang === 'ru') {
        defaultLang = userLang;
      }
      changeLanguage(defaultLang);
    }
  }, [changeLanguage]);
  return (
    <LanguagesWrapper>
      <CurrentItem onClick={toggling}>
        <span className={`language flag-icon ${selectedOption.icon || defaultOption.icon}`} />
        {selectedOption.title || defaultOption.title}
      </CurrentItem>
      {isOpen && (
        <ListItems>
          {filtered.map((option) => (
            <ListItem onClick={onOptionClicked(option)} key={option.lang}>
              <span className={`language flag-icon ${option.icon}`} />
              {option.title}
            </ListItem>
          ))}
        </ListItems>
      )}
    </LanguagesWrapper>
  );
}
